import React from 'react';
import { Outlet } from 'react-router-dom';

import Header from '../Header/Header';
import ModalEditProfile from '../Profile/ModalEditProfile/ModalEditProfile';
import Modals from '../ModalInfo/Modals';
import Notification from '../Notification/Notification';
const Layout = () => {
  const date = new Date();
  const token = JSON.parse(localStorage.getItem('token'));

  return (
    <>
      <Header />
      <ModalEditProfile />
      <Modals />
      <Notification />
      
      <div className={`${token ? 'wrapper' : 'wrapper-sm'}`}>
        <Outlet />
      </div>

      <footer className="footer">
        <span>Copyright &copy; 2019-{date.getFullYear()} <span className="ml-4">NewBrain</span></span>
      </footer>
    </ >
  );
};

export default Layout;