import React, { useContext, useEffect } from 'react';
import { LayoutContext } from '../../contexts/layout.context';
import { useDispatch, useSelector } from 'react-redux';
import { authSelectors } from '../../store/selectors/auth';
import { getToken } from '../../utils/token';
import { testSelectors } from '../../store/selectors/test';
import { useNavigate } from 'react-router-dom';
import { getUserInfoThunk } from '../../store/thunks/profile';
import actions from '../../store/actions';
import Head from '../../components/Head/Head';
import Button from '../../common/Buttons/Button';
import Spinner from '../../common/Spinner/Spinner';
import AttemptsContent from '../../components/Attempts/AttemptsContent';
import { attemptsSelectors } from '../../store/selectors/attempts';
import { continueTestThunk } from '../../store/thunks/test';

const TestFinish = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { setLayout } = useContext(LayoutContext);
  const auth = useSelector(authSelectors);
  const token = auth.token;
  const test = useSelector(testSelectors);
  const attempts = useSelector(attemptsSelectors);
  const testInfo = test.test;
  const finished = test.finished;
  const attemptsBlock = finished?.el_homework_tasks;
  const load = attempts.load;
  const currentTestId = JSON.parse(localStorage.getItem('currentTestId'));

  getToken(token);
  
  useEffect(() => {
    document.title = 'Тест | NewBrain';
    setLayout({ page: 'Тест' });
  }, [setLayout]);

  useEffect(() => {
    if(token) {
      dispatch(getUserInfoThunk({token}));
    }
  }, [token]);

  useEffect(() => {
    if(currentTestId && token) {
      const data = {id: currentTestId, token};
      dispatch(continueTestThunk(data));
    }
  }, [token]);

  const goBack = () => {
    localStorage.removeItem('homeworkElId');
    localStorage.removeItem('homeworkId');
    localStorage.removeItem('homeworkBlockId');
    localStorage.removeItem('currentTestId');
    dispatch(actions.test.getTest({}));
    dispatch(actions.test.getTestType(''));
    dispatch(actions.test.getTestAnswers([]));
    dispatch(actions.test.finishedTest({}));
    dispatch(actions.test.getTestError(''));
    dispatch(actions.test.checkTest({}));
    navigate('/homework');
  };

  return (
    <div className='test container'>
      <Head
        textHead={testInfo.el_homework_block_title}
        subtitle={`${testInfo.program_name} - ${testInfo.lesson_name} - ${testInfo.homework_block_name}`}
        goBack={goBack} 
      />
      <div className="test__container test__container--lg">
        <div className="test__plug">
          {finished.message}
          <Button buttonBg='green' textSize='md' btnRadius='md' onClick={() => goBack()}>{finished.button_name}</Button>
        </div>

        {load ? <Spinner /> : <AttemptsContent attemptsInfo={finished} attemptsBlock={attemptsBlock} />}
      </div>
    </div>
  );
};

export default TestFinish;