import React from 'react';
import { attemptsHint } from './constants';
import './attempts-hint.scss';

const AttemptsTooltipHint = () => {
  return (
    <div className='attempts-hint'>
      {attemptsHint.map((item, idx) => (
        <div className='attempts-hint__block' key={idx}>
          <div className={`attempts-hint__text attempts-hint__text--${item.lineColor}`}>
            {item.text}
          </div>
        </div>
        ))}
    </div>
  );
};

export default AttemptsTooltipHint;