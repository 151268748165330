import React from 'react';
import PropTypes from 'prop-types';
import sprite from '../../img/sprites.svg';
import './head.style.scss';

const Head = ({ textHead, goBack, blockBtn, subtitle, subtitleSecond, tooltip }) => {
  return (
    <div className="head">
      {(goBack && !blockBtn) && 
      <button type='button' className="head__back" aria-label='go back' onClick={() => goBack()}>
        <svg className="icon icon--violet"><use href={`${sprite}#arrow`}></use></svg>
        <span>Назад</span>
      </button>}
      <h2 className="head__title">
        {textHead}
      </h2>
      {subtitle && <span className='head__subtitle'>{subtitle}</span>}
      {subtitleSecond && <span className='head__subtitle'>{subtitleSecond}</span>}
      {tooltip &&  <div className="head__tooltip">{tooltip}</div>}
    </div>
  );
};

Head.propTypes = {
  textHead: PropTypes.string,
  goBack: PropTypes.any,
  blockBtn: PropTypes.bool,
  subtitle: PropTypes.string,
  subtitleSecond: PropTypes.string,
  tooltip: PropTypes.any,
};

export default Head;