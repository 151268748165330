import types from '../types/index';

const initialState = {
  info: {},
  modal: false,
  error: '',
  load: false,
  loadAnswers: false,
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.attempts.GET_ATTEMPTS_CURRENT:
      return {
        ...state, info: payload
      };
    case types.attempts.GET_ATTEMPTS_ERROR:
      return {
        ...state, error: payload
      };
    case types.attempts.OPEN_NOTIFICATION_MODAL:
      return {
        ...state, modal: true
      };
    case types.attempts.CLOSE_NOTIFICATION_MODAL:
      return {
        ...state, modal: false
      };
    case types.attempts.START_FETCHING_ATTEMPTS:
      return {
        ...state, load: true
      };
    case types.attempts.STOP_FETCHING_ATTEMPTS:
      return {
        ...state, load: false
      };
    case types.attempts.START_FETCHING_ATTEMPTS_ANSWERS:
      return {
        ...state, loadAnswers: true
      };
    case types.attempts.STOP_FETCHING_ATTEMPTS_ANSWERS:
      return {
        ...state, loadAnswers: false
      };
    default: {
      return state;
    }
  }
};

export default reducer;