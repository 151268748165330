import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import actions from '../../store/actions';
import { getNotificationThunk } from '../../store/thunks/notification';
import { authSelectors } from '../../store/selectors/auth';
import { getUserInfoThunk } from '../../store/thunks/profile';
import { notificationSelectors } from '../../store/selectors/notification';
import ButtonIcon from '../../common/ButtonIcon/ButtonIcon';
import NotificationHeaderItem from './NotificationHeaderItem';
import './notification-header.scss';

const NotificationHeader = () => {
  const dispatch = useDispatch();
  const notification = useSelector(notificationSelectors);
  const modalHeader = notification.modalHeader;
  const allNotifications = notification.allNotifications;
  const title = notification.titleNotifications;
  const auth = useSelector(authSelectors);
  const token = auth.token;
  const isOpen = modalHeader ? 'open' : '';

  const [sortedNotification, setSortedNotification] = useState([]);

  useEffect(() => {
    if(isOpen) {
      if (isOpen) {
        document.body.classList.add('open');
      } else {
        document.body.classList.remove('open');
      }

      return () => {
        document.body.classList.remove('open');
      };
    }
  }, [isOpen]);

  useEffect(() => {
    if(allNotifications.length > 0) {
      const sortedNotifications = [...allNotifications].sort(
        (a, b) => b.not_read_comment_count - a.not_read_comment_count
      );
      setSortedNotification(sortedNotifications);
    }
  }, [allNotifications]);

  const closeModal = () => {
    dispatch(actions.notification.closeHeaderNotificationModal());
    dispatch(actions.notification.getAllNotifications([]));
    dispatch(actions.notification.getTitleAllNotifications(''));
  };

  const getNotification = (token, id) => {
    const data = {token, id};
    dispatch(getNotificationThunk(data));
    dispatch(actions.notification.openNotificationModal());
    dispatch(actions.notification.notificationCommentId(id));
    closeModal();
    dispatch(getUserInfoThunk({token}));
  };

  return (
    <div className={`notification-header ${isOpen}`}>
      <div className="overlay" onClick={() => closeModal()}></div>
      <div className="notification-header__container">
          <div className="notification-header__head">
            <div className="notification-header__title">{title}</div>
            <ButtonIcon icon='close' iconColor='violet' onClick={() => closeModal()} />
          </div>

          <div className="notification-header__body">
            {sortedNotification?.length > 0 ? sortedNotification.map(notification => (
              <NotificationHeaderItem 
                key={notification.el_homework_task_id} 
                notification={notification} 
                onClick={() => getNotification(token, notification.el_homework_task_id)} 
              />
            )) : <div className="notification-header__plug">Повідомлень ще немає</div>}
          </div>
      </div>
    </div>
  );
};

export default NotificationHeader;