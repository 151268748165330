import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { attendanceColor, homeworkColors } from '../constants';
import sprite from '../../../img/sprites.svg';
import actions from '../../../store/actions';
import './schedule-event.scss';

const SheduleEvent = ({event, setEvents}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const attendanceClass = event.attendance_status ?  attendanceColor[event.attendance_status] : '';

  const setHomeworkPage = (e) => {
    e.stopPropagation();
    setEvents([]);
    dispatch(actions.schedule.getDayEvent([]));
    localStorage.setItem('event', JSON.stringify(event));
    localStorage.setItem('eventLessonId', JSON.stringify(event.event_lesson_id));
    navigate('/homework', { state: { from: location.pathname } });
  };

  return (
    <div className={`schedule-event ${attendanceClass}`}>
      <img src={event.image} alt={event.direction_short_name} className="schedule-event__flag" />
      <div className="schedule-event__name">{event.direction_short_name}</div>
      {event.is_show_homework && <span className='schedule-event__btn btn-icon btn-icon--white' onClick={(e) => setHomeworkPage(e)}>
        <svg className={`icon icon--sm icon--${homeworkColors[event.homework_icon_color]}`}><use href={`${sprite}#book`}></use></svg>
      </span>}
      <span className="schedule-event__time">{event.hour}:00</span>
    </div>
  );
};

SheduleEvent.propTypes = {
  setEvents: PropTypes.func,
  event: PropTypes.object
};


export default SheduleEvent;