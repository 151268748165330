import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getToken } from '../../utils/token';
import { authSelectors } from '../../store/selectors/auth';
import { continueTestThunk, getTestThunk } from '../../store/thunks/test';
import { testSelectors } from '../../store/selectors/test';
import { LayoutContext } from '../../contexts/layout.context';
import { useNavigate } from 'react-router-dom';
import actions from '../../store/actions';
import Head from '../../components/Head/Head';
import Progressbar from '../../common/Progress/Progressbar';
import TestQuestion from '../../components/Test/TestQuestion';
import Spinner from '../../common/Spinner/Spinner';
import ModalTest from '../../components/ModalTest/ModalTest';
import TestTypeAnswers from '../../components/Test/TestTypeAnswers';
import TestTranslate from '../../components/Test/TestTranslate';
import TestConformity from '../../components/Test/TestConformity';
import { getUserInfoThunk } from '../../store/thunks/profile';

const Test = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { setLayout } = useContext(LayoutContext);

  const homeworkId = JSON.parse(localStorage.getItem('homeworkElId'));
  const currenBlockId = JSON.parse(localStorage.getItem('homeworkId'));
  const currentTestId = JSON.parse(localStorage.getItem('currentTestId'));
  const auth = useSelector(authSelectors);
  const token = auth.token;
  const test = useSelector(testSelectors);
  const testInfo = test.test;
  const type = test.type;
  const load = test.load;
  const finished = test.finished;

  const [openModal, setOpenModal] = useState(false);
  const [isStartTest, setIsStartTest] = useState(JSON.parse(localStorage.getItem('startTest')));

  getToken();

  useEffect(() => {
    document.title = 'Тест | NewBrain';
    setLayout({ page: 'Тест' });
  }, [setLayout]);

  useEffect(() => {
    if(token) {
      dispatch(getUserInfoThunk({token}));
    }
  }, [token]);

  useEffect(() => {
    if(isStartTest && homeworkId && currenBlockId && token) {
      const data = {homeworkId, blockId: currenBlockId, token};
      dispatch(getTestThunk(data));
      setIsStartTest(false);
      localStorage.removeItem('startTest');
  }
  }, [token]);

  useEffect(() => {
    if(!isStartTest && currentTestId && token) {
      const data = {id: currentTestId, token};
      dispatch(continueTestThunk(data));
    }
  }, [token]);

  useEffect(() => {
    if(testInfo?.el_homework_block_id) {
      localStorage.setItem('currentTestId', JSON.stringify(testInfo.el_homework_block_id || 0));
    }
  }, [testInfo]);

  useEffect(() => {
    if(finished?.is_finished) {
      navigate('/homework/test/finish');
    }
  }, [finished]);

  const goBack = (currentTestId) => {
    localStorage.removeItem('homeworkId');
    localStorage.removeItem('homeworkElId');
    localStorage.removeItem('homeworkBlockId');
    localStorage.setItem('currentTestId', JSON.stringify(currentTestId || 0));
    localStorage.removeItem('answersArr');
    localStorage.removeItem('definitionArr');
    dispatch(actions.test.getTest({}));
    dispatch(actions.test.getTestType(''));
    dispatch(actions.test.getTestAnswers([]));
    dispatch(actions.test.checkTest({}));
    dispatch(actions.test.getTestError(''));
    navigate('/homework');
  };

  return (
    load ? <Spinner /> : 
    <div className='test container'>
      <ModalTest token={token} openModal={openModal} setOpenModal={setOpenModal} />
      <Head
        textHead={testInfo.el_homework_block_title}
        subtitle={`${testInfo.program_name} - ${testInfo.lesson_name} - ${testInfo.homework_block_name}`}
        goBack={() => goBack(testInfo.el_homework_block_id)} 
      />
      <div className="test__container">
        <Progressbar testInfo={testInfo} />
        <div className="test__question">
          <TestQuestion 
            title={testInfo.el_homework_block_description} 
            question={testInfo.homework_task_name} 
            conformity={type === 'conformity' ? true : false} 
          />
        </div>
        {type === 'conformity' && <TestConformity setOpenModal={setOpenModal} />}
        {type === 'translate' && <TestTranslate setOpenModal={setOpenModal} />}
        {type === 'test' && <TestTypeAnswers setOpenModal={setOpenModal} />} 
      </div>
    </div>
  );
};

export default Test;