import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { notificationSelectors } from '../../store/selectors/notification';
import actions from '../../store/actions';
import NotificationMessage from './NotificationMessage';
import NotificationHead from './NotificationHead';
import NotificationFooter from './NotificationFooter';
import './notification.scss';

const Notification = () => {
  const dispatch = useDispatch();
  const notification = useSelector(notificationSelectors);
  const modal = notification.modal;
  const title = notification?.notifications?.title;
  const sendText = notification?.notifications?.chat_text;
  const comments = notification.comments;
  const comment = notification.comment;
  const isOpen = modal ? 'open' : '';
  const headRef = useRef(null);

  const [commentsArr, setCommentsArr] = useState([]);
  const [lastMessage, setLastMessage] = useState(null);
  const [headHeight, setHeadHeight] = useState(0);

  useEffect(() => {
    if(isOpen) {
      if (isOpen) {
        document.body.classList.add('open');
      } else {
        document.body.classList.remove('open');
      }

      return () => {
        document.body.classList.remove('open');
      };
    }
  }, [isOpen]);

  useEffect(() => {
    if(comments.length > 0) {
      setCommentsArr(comments);
    }
  }, [comments]);

  useEffect(() => {
    if (Object.keys(comment).length > 0) {
      setCommentsArr(prev => [...prev, comment]);
    }
  }, [comment]);


  useEffect(() => {
    if (commentsArr?.length > 0 && lastMessage?.current && isOpen) {
      lastMessage?.current.scrollIntoView();
    }
  }, [commentsArr, lastMessage?.current, isOpen]);

  useEffect(() => {
    if (headRef.current) {
      setHeadHeight(headRef.current.clientHeight);
    }
  }, [title]);

  const closeModal = () => {
    dispatch(actions.notification.closeNotificationModal());

    let timer = setInterval(() => {
      dispatch(actions.notification.notificationCommentId(0));
      dispatch(actions.notification.getComments([]));
      dispatch(actions.notification.getComment([]));
      dispatch(actions.notification.getNotificationError(''));
      setCommentsArr([]);
      clearInterval(timer);
    }, 400);
  };
  
  return (
    <div className={`notification ${isOpen}`}>
      <div className="overlay" onClick={() => closeModal()}></div>
      <div className="notification__container">
      <div ref={headRef}>
          <NotificationHead closeModal={closeModal} title={title} />
        </div>

        <div className="notification__body" style={{ height: `calc(100% - ${headHeight}px)` }}>
          {<div className="notification__messages">
            {commentsArr?.length > 0 ? commentsArr.map((comment, idx) => (
              <NotificationMessage 
                key={idx} 
                last={idx === commentsArr.length - 1} 
                comment={comment} 
                setLastMessage={setLastMessage} 
                sendText={sendText} 
              />
            )) : <div className="notification__plug">Повідомлень ще немає</div>}
          </div>}

          <div className="notification__footer">
            <NotificationFooter />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Notification;