import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { notificationSelectors } from '../../../store/selectors/notification';
import { useDispatch, useSelector } from 'react-redux';
import { getAllNotificationsThunk } from '../../../store/thunks/notification';
import { profileSelectors } from '../../../store/selectors/profile';
import actions from '../../../store/actions';
import Burger from '../../Burger/Burger';
import HeaderProfile from './HeaderProfile/HeaderProfile';
import Logo from '../../../img/logo.png';
import ButtonIcon from '../../../common/ButtonIcon/ButtonIcon';
import NotificationHeader from '../../NotificationHeader/NotificationHeader';


const HeaderActions = ({ setOpenMenu, token }) => {
  const dispatch = useDispatch();
  const user = useSelector(profileSelectors);
  const notification = useSelector(notificationSelectors);
  const modalHeader = notification.modalHeader;
  const userInfo = user?.user;
  const isUnread = user?.user?.is_unread_comment ? 'counter' : '';
  const isOpen = modalHeader ? 'open' : '';

  useEffect(() => {
    if (isOpen) {
      const handleClickOutside = (event) => {
        const modal = document.querySelector('.header-actions__notification');

        if (modal && !modal.contains(event.target) ) {
          closeModal();
        }
      };

      const burger = document.querySelector('.header-burger');

      if(burger) {
        burger.style.zIndex = 1;
      }

      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
      
    }
  }, [isOpen]);
  

  const setHeaderNotification = (token) => {
    if(isOpen && window.innerWidth > 768) {
      closeModal();
    } else {
      dispatch(getAllNotificationsThunk({ token }));
      dispatch(actions.notification.openHeaderNotificationModal());
    }
  };

  const closeModal = () => {
    const notification = document.querySelector('.header-actions__notification');

    if(notification) {
      notification.style.zIndex = 35;
    }
    
    dispatch(actions.notification.closeHeaderNotificationModal());
    dispatch(actions.notification.getAllNotifications([]));
    dispatch(actions.notification.getTitleAllNotifications(''));
  };

  return (
    <div className="header-top">
      <div className="container">
        <NavLink to='/' className="logo">
          <img src={Logo} alt="logo New Brain" width='1' height='1' decoding='async' />
        </NavLink>
        {
          token &&
          <div className='header-actions'>
            <NavLink to='/directions' className='header-actions__link btn btn-violet'>
              <span>БЕЗКОШТОВНІ</span>ПРОБНІ
            </NavLink>

            {userInfo?.telegram_bot_link && <NavLink to={userInfo.telegram_bot_link} target='_blank'>
              <ButtonIcon icon='send-up' iconColor='violet' />
            </NavLink>}

            <div className={`header-actions__notification ${isUnread}`}>
              <ButtonIcon icon='message-question' iconColor='violet' onClick={() => setHeaderNotification(token)} />
              <NotificationHeader />
            </div>

            {window.innerWidth > 768 && <HeaderProfile link={true} />}

            <div className="header-burger">
              <Burger setOpenMenu={setOpenMenu} />
            </div>
          </div>
        }
      </div>
    </div>
  );
};

HeaderActions.propTypes = {
  setOpenMenu: PropTypes.func.isRequired,
  token: PropTypes.string
};

export default HeaderActions;