import React from 'react';
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';
import ButtonIcon from '../../../../common/ButtonIcon/ButtonIcon';

import './program-item.style.scss';
import { homeworkColors } from '../../../schedule/constants';

const CourseProgramItem = ({ item }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const borderColor = (status) => {
    if (status === 1) {
      return 'br-green';
    }

    if (status === 2) {
      return 'br-yellow';
    }

    if (status === 3) {
      return 'br-red';
    }

    return '';
  };

  const setLink = (link) => {
    window.open(link, '_blank');
  };

  const setHomeworkPage = (event) => {
    localStorage.setItem('event', JSON.stringify(event));
    localStorage.setItem('eventLessonId', JSON.stringify(event.event_lesson_id));
    navigate('/homework', { state: { from: location.pathname } });
  };

  return (
    <div className='program-item'>
      <div className="program-item-content">
        <div className={`program-item__count ${borderColor(item.attendance_status)}`}>{item.order + 1}</div>

        <div className="program-item__body">
          <div className="program-item__details">
            <h3 className="program-item__title">{item.topic || '-'}</h3>
            <h3 className="program-item__subtitle">{item.date || '-'}</h3>
          </div>

          <div className="program-item__btns">
            {item.presentation_link && <ButtonIcon 
              classes='program-item__btn' 
              icon='monitor' 
              btnBg='white' 
              iconColor='green' 
              btnSize='lg' 
              iconSize='md' 
              onClick={() => setLink(item.presentation_link)} 
            />}
            {item.vocabulary_link && <ButtonIcon 
              classes='program-item__btn' 
              icon='list' 
              btnBg='white' 
              iconColor='green' 
              btnSize='lg' 
              iconSize='md' 
              onClick={() => setLink(item.vocabulary_link)} 
            />}
            {item.is_show_homework && <ButtonIcon 
              classes='program-item__btn' 
              icon='book' 
              btnBg='white' 
              iconColor={homeworkColors[item.homework_icon_color]} 
              btnSize='lg' 
              iconSize='md' 
              onClick={() => setHomeworkPage(item)} 
            />}
          </div>
        </div>

        {/* <div className="program-item__buttons"> */}
          {/* {item.is_show_words && (
            <button
              className="program-item__btn"
              onClick={() => window.open(item.words_url, '_blank')}
            >
              <svg className="program-item__icon"><use href={`${sprite}#dictionary`}></use></svg>
              <svg className="icon program-item__arrow"><use href={`${sprite}#arrow`}></use></svg>
            </button>
          )} */}

          {/* {item.presentation && <button
            className="program-item__btn"
            onClick={() => window.open(item.presentation, '_blank')}
          >
            <svg className="program-item__icon"><use href={`${sprite}#monitor`}></use></svg>
            <svg className="icon program-item__arrow"><use href={`${sprite}#arrow`}></use></svg>
          </button>} */}
        {/* </div> */}
      </div>
    </div>
  );
};

CourseProgramItem.propTypes = {
  item: PropTypes.object.isRequired,
};

export default CourseProgramItem;
