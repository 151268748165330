import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ButtonIcon from '../../common/ButtonIcon/ButtonIcon';
import FieldTextarea from '../../common/Fields/FieldTextarea';
import Button from '../../common/Buttons/Button';
import './modal-test.scss';
import { useDispatch, useSelector } from 'react-redux';
import { informTestThunk } from '../../store/thunks/test';
import { testSelectors } from '../../store/selectors/test';
import actions from '../../store/actions';
import ErrorField from '../../common/Errors/ErrorField';

const ModalTest = ({token, openModal, setOpenModal}) => {
  const dispatch = useDispatch();

  const test = useSelector(testSelectors);
  const testInfo = test.test;
  const testInform = test.inform;
  const informError = test.informError;
  const isOpen = openModal ? 'open' : '';

  const [errorText, setErrorText] = useState('');
  const [informText, setInformText] = useState('');

  useEffect(() => {
    if(informText) {
      let timer = setInterval(() => {
        closeModal();
        clearInterval(timer);
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [informText]);

  useEffect(() => {
    if(testInform) {
      setInformText(testInform);
    }
  }, [testInform]);

  const sendErrorText = () => {
    const data = {text: errorText, homeworkId: testInfo.el_homework_id, taskId: testInfo.homework_task_id, token};
    setErrorText('');
    dispatch(actions.test.informTestError(''));
    dispatch(informTestThunk(data));
  };

  const closeModal = () => {
    setOpenModal(false);
    dispatch(actions.test.informTestError(''));
    setErrorText('');

    let timer = setTimeout(() => {
      dispatch(actions.test.informTest(''));
      setInformText('');
      clearTimeout(timer);
    }, 400);
  };

  return (
    <div className={`modal-test ${isOpen}`}>
      <div className="overlay" onClick={() => closeModal()}></div>
      <div className="modal-test__container">
      {!informText ? <>
        <div className="modal-test__head">
          <div className="modal-test__title">
            Повідомити про помилку
          </div>
          <ButtonIcon icon='close' iconColor='violet' classes='modal-test__close' onClick={() => closeModal()} />
        </div>
        <div className="modal-test__body">
          <div>
            <FieldTextarea         
              id='error_text' 
              name="error_text" 
              value={errorText} 
              placeholder='Опишіть помилку' 
              onChange={(e) => setErrorText(e.target.value)} 
              formClass=""  
            />
            {informError && <ErrorField errorText={informError} />}
          </div>
            
          <Button buttonBg='green' textSize='md' btnRadius='md' iconSvg='flag' iconColor='white' onClick={() => sendErrorText()} >
            Відправити
          </Button>
        </div>
      </>
      : <div className="modal-test__plug">
        {informText}
      </div>}
      </div>
    </div>
  );
};

ModalTest.propTypes = { 
  token: PropTypes.string,
  openModal: PropTypes.bool,
  setOpenModal: PropTypes.func
};

export default ModalTest;