import React from 'react';
import PropTypes from 'prop-types';
import './test-question.scss';

const TestQuestion = ({title, question, conformity}) => {
  return (
    <div className={`test-question ${conformity ? 'test-question--sm' : ''}`}>
      {title && <div className="test-question__title">{title}</div>}
      {question && <div className="test-question__text">{question}</div>}
    </div>
  );
};

TestQuestion.propTypes = { 
  title: PropTypes.string,
  question: PropTypes.string,
  conformity: PropTypes.bool
};

export default TestQuestion;