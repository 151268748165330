import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { authSelectors } from '../../store/selectors/auth';
import { testSelectors } from '../../store/selectors/test';
import { checkTestThunk, continueTestThunk } from '../../store/thunks/test';
import actions from '../../store/actions';
import Button from '../../common/Buttons/Button';
import TestAnswer from './TestAnswer';

const TestTypeAnswers = ({setOpenModal}) => {
  const dispatch = useDispatch();

  const auth = useSelector(authSelectors);
  const token = auth.token;
  const test = useSelector(testSelectors);
  const testInfo = test.test;
  const answers = test.answers;
  const error = test.error;
  const check = test.check;
  // const loadBtn = test.loadBtn;

  const [isAnswered, setIsAnswered] = useState(false);
  const [chooseAnswer, setChooseAnswer] = useState({
    answerId: null,
    answerName: '',
  });

  // const getCurrentAnswer = (id, name, blockId, taskId, testId, token) => {
  //   setChooseAnswer({ answerId: id, answerName: name });
  //   handleCheckAnswer(blockId, taskId, testId, token);
  // };

  const handleCheckAnswer = (blockId, taskId, id, name, token) => {
    if (isAnswered && !error) return;

    const data = {blockId, taskId, data: {answer_id: id}, token};
    
    setChooseAnswer({ answerId: id, answerName: name });
    dispatch(actions.test.getTestError(''));
    dispatch(actions.test.checkTest({}));
    dispatch(checkTestThunk(data));
    setIsAnswered(true);
  };

  const setNextTest = (id, token) => {
    const data = {id, token};
    setChooseAnswer({answerId: null, answerName: ''});
    setIsAnswered(false);
    dispatch(actions.test.checkTest({}));
    dispatch(actions.test.getTestError(''));
    localStorage.setItem('currentTestId', JSON.stringify(id));
    dispatch(continueTestThunk(data));
  };

  const setTestModal = () => {
    setOpenModal(true);
  };

  return (
    <>
      <div>
        <div className="test__answers">
          {answers.length > 0 && answers.map((answer, idx) => {
            const isChosen = chooseAnswer?.answerId === answer.id;
            const isCorrect = check?.correct_answer_id === answer.id;
            const isIncorrect = Object?.keys(check)?.length > 0 && isAnswered && isChosen && !isCorrect;
            const shouldHighlightCorrect = isAnswered && isCorrect;

            return (
              <TestAnswer 
                key={answer.id} 
                count={idx + 1}
                answer={answer.answer} 
                choosen={isChosen}
                correct={shouldHighlightCorrect}
                incorrect={isAnswered && isIncorrect}
                disabled={isAnswered && !error ? true : false}
                // onClick={!isAnswered || error ? () => getCurrentAnswer(answer.id, answer.answer, testInfo.el_homework_block_id, testInfo.homework_task_id, chooseAnswer.answerId, token) : undefined}
                onClick={!isAnswered || error 
                  ? () => handleCheckAnswer(testInfo.el_homework_block_id, testInfo.homework_task_id, answer.id, answer.answer, token) 
                  : undefined
                } 
              />
            );
          })}
        </div>

        {error && <div className="test__error error-message">{error}</div> }
      </div>
      <div className="test__btns">
        {/* {isAnswered && !error
        ?  */}

        <Button buttonBg='green' textSize='md' btnRadius='md' disabled={!isAnswered && !error ? true : false}
          onClick={() => setNextTest(testInfo.el_homework_block_id, token)}>
          Далі
        </Button>

        {/* : <Button buttonBg='green' textSize='md' btnRadius='md' disabled={loadBtn ? true : false}
            onClick={() => handleCheckAnswer(testInfo.el_homework_block_id, testInfo.homework_task_id, chooseAnswer.answerId, token)}
          >
            Перевірити
          </Button>} */}
        <Button buttonBg='light' iconSvg='flag' iconColor='violet' onClick={() => setTestModal()}>
          Повідомити про помилку
        </Button>
      </div>
    </>
  );
};

TestTypeAnswers.propTypes = {
  setOpenModal: PropTypes.func,
};

export default TestTypeAnswers;