import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import actions from '../../../store/actions';

import { LayoutContext } from '../../../contexts/layout.context';

import { menu } from './constans';

import sprite from '../../../img/sprites.svg';

const HeaderNav = () => {
  const dispatch = useDispatch();
  const { layout } = useContext(LayoutContext);

  const setPage = (page) => {
    dispatch(actions.schedule.getDayEvent([]));
    dispatch(actions.finances.getFinancesUsersDocuments([]));
    localStorage.removeItem('product_id');
    localStorage.removeItem('invoice_id');
    dispatch(actions.finances.setParamInvoice(null));
    dispatch(actions.finances.setParamPaymentUuid(null));

    if(page === '/schedule') {
      localStorage.removeItem('event');
      localStorage.removeItem('eventLessonId');
      sessionStorage.removeItem('initialRoute');
    }
  };
  
  return (
    <div className="container">
      <ul className="header-menu">
        {menu.map(item => (
          <li 
            key={item.title} 
            className='header-menu__item'
            onClick={() => setPage(item.link)}
          >
            <NavLink to={item.link} className={`${layout.page === item.title ? 'active' : ''}`} >
              <svg className="icon"><use href={`${sprite}#${item.icon}`}></use></svg>
              <span>{item.title}</span>
            </NavLink>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default HeaderNav;