import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { testSelectors } from '../../store/selectors/test';
import { useDispatch, useSelector } from 'react-redux';
import { authSelectors } from '../../store/selectors/auth';
import { checkTestThunk, continueTestThunk } from '../../store/thunks/test';
import actions from '../../store/actions';
import Button from '../../common/Buttons/Button';
import TestAnswer from './TestAnswer';

const TestConformity = ({ setOpenModal }) => {
  const dispatch = useDispatch();
  const auth = useSelector(authSelectors);
  const token = auth.token;

  const test = useSelector(testSelectors);
  const testInfo = test.test;
  const answers = test.answers;
  const definitions = test.definitions;
  const check = test.check;

  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [selectedDefinition, setSelectedDefinition] = useState(null);
  const [answersArr, setAnswersArr] = useState(answers);
  const [definitionArr, setDefinitionArr] = useState(definitions);
  const [copiedAnswersArr, setCopiedAnswersArr] = useState(answers);
  const [copiedDefinitionArr, setCopiedDefinitionArr] = useState(definitions);

  useEffect(() => {
    if(answers.length > 0 && definitions.length > 0) {
      setAnswersArr(answers);
      setDefinitionArr(definitions);
      setCopiedAnswersArr(answers);
      setCopiedDefinitionArr(definitions);
      dispatch(actions.test.checkTest({}));
      dispatch(actions.test.getTestAnswers([]));
      dispatch(actions.test.getTestDefinitions([]));
    }
  }, [answers, definitions]);

  useEffect(() => {
    if(selectedAnswer && selectedDefinition && selectedAnswer !== selectedDefinition) {
      let time = 1;

      let timer = setInterval(() => {
        const incorrect = document.querySelectorAll('.incorrect');
        const shake = document.querySelectorAll('.shake');

        if(time) {
          if (incorrect.length > 0) {
            incorrect.forEach(el => el.classList.remove('incorrect'));
          }
  
          if(shake.length > 0) {
            shake.forEach(el => el.classList.remove('shake'));
          }
        }

        setSelectedAnswer(null);
        setSelectedDefinition(null);

        --time;
        clearInterval(timer);
      }, 1000);
  
      return () => {
        clearInterval(timer);
      };
    }
  }, [selectedAnswer, selectedDefinition]);

  useEffect(() => {
    if (selectedAnswer && selectedDefinition && selectedAnswer === selectedDefinition) {
      const updatedAnswers = copiedAnswersArr.filter(answer => 
        !(selectedAnswer === answer.id)
      );
      
      const updatedDefinitions = copiedDefinitionArr.filter(definition =>
        !(selectedDefinition === definition.id)
      );

      setCopiedAnswersArr(updatedAnswers);
      setCopiedDefinitionArr(updatedDefinitions);

      let timer = setInterval(() => {
        setSelectedAnswer(null);
        setSelectedDefinition(null);
        clearInterval(timer);
      }, 1000);
    }

    if(copiedAnswersArr.length === 0 && copiedDefinitionArr.length === 0) {
      const data = {blockId: testInfo.el_homework_block_id, taskId: testInfo.homework_task_id, token};
      dispatch(checkTestThunk(data));
    }

  }, [selectedAnswer, selectedDefinition]);

  const handleCheckAnswer = (id) => {
    if(selectedAnswer && selectedAnswer === id) {
      setSelectedAnswer(null);
    } else {
      setSelectedAnswer(id);
    }
  };

  const handleCheckDefinition = (id) => {
    if(selectedDefinition && selectedDefinition === id) {
      setSelectedDefinition(null);
    } else {
      setSelectedDefinition(id);
    }
  };

  const setTest = (id, token) => {
    const data = {id, token};
    dispatch(actions.test.getTestAnswers([]));
    dispatch(actions.test.getTestDefinitions([]));
    dispatch(actions.test.checkTest({}));
    setAnswersArr([]);
    setDefinitionArr([]);
    setCopiedAnswersArr([]);
    setCopiedDefinitionArr([]);
    dispatch(continueTestThunk(data));
  };

  const setTestModal = () => {
    setOpenModal(true);
  };

  return (
    <>
      <div className="test__conformity">
        {Object.keys(check)?.length > 0 && check.is_correct && copiedAnswersArr.length === 0 && copiedDefinitionArr.length === 0
        ? <div className='test__conformity-plug'>
          {check.text}
        </div> 
        : <>
          <div className="test__conformity-answers">
            {answersArr.map((answer, idx) => {
              const correct = selectedAnswer && selectedDefinition && (selectedAnswer === selectedDefinition) && selectedAnswer == answer.id;
              const incorrect = selectedAnswer && selectedDefinition && selectedAnswer !== selectedDefinition && selectedAnswer == answer.id;
              const hidden = !copiedAnswersArr.some(item => item.id === answer.id);

              return <TestAnswer
                key={idx}
                count={idx + 1}
                answer={answer.answer}
                size='lg'
                choosen={selectedAnswer === answer.id}
                correct={correct}
                incorrect={incorrect}
                hidden={hidden}
                onClick={() => handleCheckAnswer(answer.id)}
              />;
            })}
          </div>
          <div className="test__conformity-answers">
            {definitionArr.map((definition, idx) => {
              const correct = selectedAnswer && selectedDefinition && (selectedAnswer === selectedDefinition) && selectedDefinition == definition.id;
              const incorrect = selectedAnswer && selectedDefinition && selectedAnswer !== selectedDefinition && selectedDefinition == definition.id;
              const hidden = !copiedDefinitionArr.some(item => item.id === definition.id);

              return <TestAnswer
                key={idx}
                count={idx + 1}
                answer={definition.definition}
                size='lg'
                choosen={selectedDefinition === definition.id}
                correct={correct}
                incorrect={incorrect}
                hidden={hidden}
                onClick={() => handleCheckDefinition(definition.id)}
              />;
            })}
          </div>
        </>}
      </div>

      <div className="test__btns">
      <Button buttonBg='green' textSize='md' btnRadius='md' 
          disabled={Object.keys(check)?.length > 0 && check.is_correct && copiedAnswersArr.length === 0 && copiedDefinitionArr.length === 0 ? false : true}
          onClick={() => setTest(testInfo.el_homework_block_id, token)}
        >
          Далі
        </Button>
        <Button buttonBg='light' iconSvg='flag' iconColor='violet' onClick={() => setTestModal()}>
          Повідомити про помилку
        </Button>
      </div>
    </>
  );
};

TestConformity.propTypes = {
  setOpenModal: PropTypes.func,
};

export default TestConformity;
