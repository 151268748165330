export const statusText = {
  0: 'Не розпочато',
  1: 'Не розпочато',
  2: 'Розпочато',
  3: 'Протерміновано',
  4: 'Завершено'
};

export const statuIcon = {
  0: 'not-started-circle',
  1: 'not-started-circle',
  2: 'start-circle',
  3: 'overdue-circle',
  4: 'check-circle'
};

export const statuColor = {
  0: 'gray',
  1: 'gray',
  2: 'yellow',
  3: 'red',
  4: 'green'
};