import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { authSelectors } from '../../../store/selectors/auth';
import { getCourseProgramInfo } from '../../../store/thunks/programs';
import { programsSelectors } from '../../../store/selectors/programs';

import Head from '../../../components/Head/Head';
import CourseProgramItem from '../../../components/Programs/CourseProgram/CourseProgramItem/CourseProgramItem';
import Plug from '../../../components/plug/plug.component';
import Spinner from '../../../common/Spinner/Spinner';
import HeadTooltip from '../../../components/Tooltip/HeadTooltip';
import CourseTooltipHint from '../../../components/Tooltip/CourseTooltipHint';

const CourseProgram = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const programId = JSON.parse(localStorage.getItem('programId'));

  const auth = useSelector(authSelectors);
  const programs = useSelector(programsSelectors);
  const token = auth.token;
  const lessons = programs.lessons;
  const details = programs.programDetails;
  const load = programs.load;

  useEffect(() => {
    if(!programId) {
      navigate('/courses');
    }
  }, []);

  useEffect(() => {
    if(programId && token) {
      dispatch(getCourseProgramInfo({programId, token}));
    }
  }, [programId, token]);

  const goBack = () => {
    navigate('/courses');
    localStorage.removeItem('programId');
  };

  return (
    <section className='program container'>
      <Head 
        textHead={details.direction_name || ''} 
        subtitle={details.program_name} 
        goBack={goBack} 
        tooltip={<HeadTooltip text={<CourseTooltipHint />} />} 
      />
      {load ? <Spinner /> 
      : <div className="program__cards">
        {lessons.length > 0 
        ? lessons.map((item, idx) => <CourseProgramItem key={idx}  item={item} />)
        : <Plug plug="Дані про програму курсу відсутні!" icon="icon-closed" />}
      </div>}

    </section>
  );
};

export default CourseProgram;