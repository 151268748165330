import types from '../types';

const openNotificationModal = (payload) => ({
  type: types.notification.OPEN_NOTIFICATION_MODAL,
  payload,
});

const closeNotificationModal = (payload) => ({
  type: types.notification.CLOSE_NOTIFICATION_MODAL,
  payload,
});

const openHeaderNotificationModal = (payload) => ({
  type: types.notification.OPEN_HEADER_NOTITFICATION_MODAL,
  payload,
});

const closeHeaderNotificationModal = (payload) => ({
  type: types.notification.CLOSE_HEADER_NOTITFICATION_MODAL,
  payload,
});

const getNotification = (payload) => ({
  type: types.notification.GET_NOTIFICATION,
  payload,
});

const getComments = (payload) => ({
  type: types.notification.GET_COMMENTS,
  payload,
});

const getComment = (payload) => ({
  type: types.notification.GET_COMMENT,
  payload,
});

const getNotificationError = (payload) => ({
  type: types.notification.GET_NOTIFICATION_ERROR,
  payload,
});

const notificationCommentId = (payload) => ({
  type: types.notification.NOTIFICATION_COMMENT_ID,
  payload,
});

const getAllNotifications = (payload) => ({
  type: types.notification.GET_ALL_NOTIFACTIONS,
  payload,
});

const getTitleAllNotifications = (payload) => ({
  type: types.notification.GET_TITLE_ALL_NOTIFICATIONS,
  payload,
});

const startFetching = () => ({
  type: types.notification.START_FETCHING_NOTIFICATION
});

const stopFetching = () => ({
  type: types.notification.STOP_FETCHING_NOTIFICATION,
});

export default {
  openNotificationModal, 
  closeNotificationModal,
  getNotification, 
  getComments,
  getComment,
  getNotificationError,
  notificationCommentId,
  getAllNotifications,
  getTitleAllNotifications,
  openHeaderNotificationModal,
  closeHeaderNotificationModal,
  startFetching,
  stopFetching,
};