import React from 'react';
import PropTypes from 'prop-types';
import ButtonIcon from './../../common/ButtonIcon/ButtonIcon';
import './event-modal.scss';
import EventModalItem from './EventModalItem/EventModalItem';
import { useDispatch } from 'react-redux';
import actions from '../../store/actions';

const EventModal = ({
  setModalOpen,
  events,
  toggleClass,
  setEvents,
}) => {
  const dispatch = useDispatch();

  const closeModal = () => {
    setEvents([]);
    setModalOpen('');
    dispatch(actions.schedule.getDayEvent([]));
  };

  return (
    <div className={`event-modal ${toggleClass}`}>
      <div className="overlay" onClick={() => closeModal()}></div>
      <div className="event-modal__container">
        <div className="event-modal__head">
          <div className="event-modal__title">Події</div>
          <ButtonIcon classes={'event-modal__close'} icon='close' iconColor={'violet'} onClick={() => closeModal()} />
        </div>
        <div className="event-modal__body">
          
          {events.length > 0 ? events.map((event) => <EventModalItem key={event.id} event={event} closeModal={closeModal} />)
          : <div className="event-modal__plug">На вибрану дату події не заплановані.</div>}
        </div>
      </div>
    </div>
  );
};

EventModal.propTypes = {
  toggleClass: PropTypes.string.isRequired,
  events: PropTypes.array.isRequired,
  setModalOpen: PropTypes.func.isRequired,
  setEvents: PropTypes.func.isRequired,
};

export default EventModal;