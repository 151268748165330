import React from 'react';
import PropTypes from 'prop-types';
import Calendar from 'react-calendar';
import ScheduleDay from './ScheduleDay/ScheduleDay';
import 'react-calendar/dist/Calendar.css';
import './schedule-month.styles.scss';

const ScheduleMonth = ({
  dates,
  activeStartDate,
  setShowMonth,
  selectedDate,
  handleClick,
  setEvents,
  setEventModalHeight,
  setActiveStartDate,
}) => {
  const currentLocale = 'uk-UA';
  const now = new Date().toLocaleDateString();

  const setClasses = (date) => {
    const formattedDate = new Date(date).toLocaleDateString();
    if (formattedDate === selectedDate) {
      if(formattedDate === now) {
        return 'current-day choose-day';
      }

      return 'choose-day';
    }

    if(formattedDate === now) {
      return 'current-day';
    }

    return '';
  };

  return (
    <>
      <Calendar
        locale={currentLocale}
        navigationLabel={({ date }) => date.toLocaleDateString(currentLocale, {year: 'numeric', month: 'long'}).replace('р.','')}
        tileClassName={({ date }) => setClasses(date)}
        tileContent={({date}) => <ScheduleDay events={dates} date={date} setEvents={setEvents} />}
        maxDetail="month"
        minDetail="month"
        nextLabel=''
        next2Label=''
        prevLabel=''
        prev2Label=''
        activeStartDate={activeStartDate}
        onClickDay={(value, locale) => {
          handleClick(value, locale);
        }}
        onActiveStartDateChange={({activeStartDate}) => {
          setShowMonth(new Date(activeStartDate).toLocaleDateString(currentLocale, {day: '2-digit', month: '2-digit', year: 'numeric'}));
          setEventModalHeight();
          setEvents([]);
          setActiveStartDate(activeStartDate);
        }}
      />
    </>
  );
};

ScheduleMonth.propTypes = {
  dates: PropTypes.array.isRequired,
  setShowMonth: PropTypes.func.isRequired,
  selectedDate: PropTypes.any,
  handleClick: PropTypes.func.isRequired,
  setEvents: PropTypes.func,
  setEventModalHeight: PropTypes.func,
  activeStartDate: PropTypes.any,
  setActiveStartDate: PropTypes.func,
};

export default ScheduleMonth;