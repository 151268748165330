
import { getAllNotifications, getNotification, postNotification } from '../../api/notification/index';
import actions from '../actions/index';

export function getNotificationThunk(data) {
  return (dispatch) => {
    dispatch(actions.notification.startFetching());
    getNotification(data)
      .then(res => {
        if (res.data.info.status) {
          dispatch(actions.notification.getNotification(res.data.data));
          dispatch(actions.notification.getComments(res.data.data.el_homework_comments));
        }
      })
      .catch(error => {
        dispatch(actions.notification.getNotificationError(error.response.data?.info?.message));
      })
      .finally(() => dispatch(actions.notification.stopFetching()));
  };
}

export function sendNotificationThunk(data) {
  return (dispatch) => {
    dispatch(actions.notification.startFetching());
    postNotification(data)
      .then(res => {
        if (res.data.info.status) {
          dispatch(actions.notification.getNotification(res.data.data));
          dispatch(actions.notification.getComment(res.data.data.el_homework_comment));
        }
      })
      .catch(error => {
        dispatch(actions.notification.getNotificationError(error.response.data?.info?.message));
      })
      .finally(() => dispatch(actions.notification.stopFetching()));
  };
}

export function getAllNotificationsThunk(data) {
  return (dispatch) => {
    dispatch(actions.notification.startFetching());
    getAllNotifications(data)
      .then(res => {
        if (res.data.info.status) {
          dispatch(actions.notification.getAllNotifications(res.data.data.el_homework_comments));
          dispatch(actions.notification.getTitleAllNotifications(res.data.data.title));
        }
      })
      .catch(error => {
        dispatch(actions.notification.getNotificationError(error.response.data?.info?.message));
      })
      .finally(() => dispatch(actions.notification.stopFetching()));
  };
}