import types from '../types';

const getAttemptsCurrent = (payload) => ({
  type: types.attempts.GET_ATTEMPTS_CURRENT,
  payload,
});

const getAttemptsPrev = (payload) => ({
  type: types.attempts.GET_ATTEMPTS_PREV,
  payload,
});

const getAttemptsError = (payload) => ({
  type: types.attempts.GET_ATTEMPTS_ERROR,
  payload,
});

const openNotificationModal = (payload) => ({
  type: types.attempts.OPEN_NOTIFICATION_MODAL,
  payload,
});

const closeNotificationModal = (payload) => ({
  type: types.attempts.CLOSE_NOTIFICATION_MODAL,
  payload,
});

const startFetching = () => ({
  type: types.attempts.START_FETCHING_ATTEMPTS
});

const stopFetching = () => ({
  type: types.attempts.STOP_FETCHING_ATTEMPTS,
});

const startFetchingAnswers = () => ({
  type: types.attempts.START_FETCHING_ATTEMPTS_ANSWERS
});

const stopFetchingAnsswers = () => ({
  type: types.attempts.STOP_FETCHING_ATTEMPTS_ANSWERS,
});

export default {
  getAttemptsCurrent,
  getAttemptsPrev,
  getAttemptsError,
  openNotificationModal, 
  closeNotificationModal,
  startFetching,
  stopFetching,
  startFetchingAnswers,
  stopFetchingAnsswers
};