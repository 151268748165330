export default {
  GET_ATTEMPTS_CURRENT: 'GET_ATTEMPTS_CURRENT',
  GET_ATTEMPTS_PREV: 'GET_ATTEMPTS_PREV',
  GET_ATTEMPTS_ERROR: 'GET_ATTEMPTS_ERROR',
  OPEN_NOTIFICATION_MODAL: 'OPEN_NOTIFICATION_MODAL',
  CLOSE_NOTIFICATION_MODAL: 'CLOSE_NOTIFICATION_MODAL',

  START_FETCHING_ATTEMPTS: 'START_FETCHING_ATTEMPTS',
  STOP_FETCHING_ATTEMPTS: 'STOP_FETCHING_ATTEMPTS',

  START_FETCHING_ATTEMPTS_ANSWERS: 'START_FETCHING_ATTEMPTS_ANSWERS',
  STOP_FETCHING_ATTEMPTS_ANSWERS: 'STOP_FETCHING_ATTEMPTS_ANSWERS',
};