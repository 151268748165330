import types from '../types';

const getTest = (payload) => ({
  type: types.test.GET_TEST,
  payload,
});

const getTestAnswers = (payload) => ({
  type: types.test.TEST_ANSWERS,
  payload,
});

const getTestDefinitions = (payload) => ({
  type: types.test.TEST_DEFINITIONS,
  payload,
});

const getTestType = (payload) => ({
  type: types.test.TEST_TYPE,
  payload,
});

const checkTest = (payload) => ({
  type: types.test.CHECK_TEST,
  payload,
});

const finishedTest = (payload) => ({
  type: types.test.TEST_FINISHED,
  payload,
});

const informTest = (payload) => ({
  type: types.test.INFORM_TEST,
  payload,
});

const informTestError = (payload) => ({
  type: types.test.GET_INFORM_TEST_ERROR,
  payload,
});

const getTestError = (payload) => ({
  type: types.test.GET_TEST_ERROR,
  payload,
});

const startFetching = () => ({
  type: types.test.START_FETCHING_TEST
});

const stopFetching = () => ({
  type: types.test.STOP_FETCHING_TEST,
});

const startFetchingBtn = () => ({
  type: types.test.START_FETCHING_TEST_BTN
});

const stopFetchingBtn = () => ({
  type: types.test.STOP_FETCHING_TEST_BTN,
});

export default {
  getTest,
  getTestAnswers,
  getTestDefinitions,
  getTestType,
  checkTest,
  finishedTest,
  informTest,
  informTestError,
  getTestError,
  startFetching,
  stopFetching,
  startFetchingBtn,
  stopFetchingBtn,
};