export const courseHint = [
  {
    icon: 'monitor',
    iconColor: 'green',
    text: 'Доступна презентація',
  },
  {
    icon: 'book',
    iconColor: 'green',
    text: 'Домашнє завдання виконано',
  },
  {
    icon: 'book',
    iconColor: 'yellow',
    text: 'Домашнє завдання не виконано повністю.',
  },
  {
    icon: 'book',
    iconColor: 'red',
    text: 'Протерміноване домашнє завдання',
  },
  {
    icon: 'list',
    iconColor: 'green',
    text: 'Вордліст',
  },
];

export const homeworkHint = [
  {
    lineColor: 'green',
    text: 'Зелена смужка (пройдений тест)',
  },
  {
    lineColor: 'yellow',
    text: 'Жовта смужка (незакінчений тест)',
  },
  {
    lineColor: 'gray',
    text: 'Сіра смужка (нерозпочатий тест)',
  },
];

export const attemptsHint = [
  {
    lineColor: 'green',
    text: 'Правильна відповідь',
  },
  {
    lineColor: 'red',
    text: 'Неправильна відповідь',
  },
  {
    lineColor: 'gray',
    text: 'Без відповіді',
  },
];