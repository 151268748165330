import React, { useContext, useEffect } from 'react';
import { LayoutContext } from '../../contexts/layout.context';
import { useNavigate } from 'react-router-dom';
import { getToken } from '../../utils/token';
import { useDispatch, useSelector } from 'react-redux';
import { authSelectors } from '../../store/selectors/auth';
import { getAttemptsThunk } from '../../store/thunks/attempts';
import { attemptsSelectors } from '../../store/selectors/attempts';
import { getUserInfoThunk } from '../../store/thunks/profile';
import HeadTooltip from '../../components/Tooltip/HeadTooltip';
import Head from '../../components/Head/Head';
import AttemptsTooltipHint from '../../components/Tooltip/AttemptsTooltipHint';
import Spinner from '../../common/Spinner/Spinner';
import AttemptsContent from '../../components/Attempts/AttemptsContent';

const Attempts = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { setLayout } = useContext(LayoutContext);

  const auth = useSelector(authSelectors);
  const token = auth.token;
  const eventLessonId = JSON.parse(localStorage.getItem('eventLessonId'));
  const homeworkId = JSON.parse(localStorage.getItem('homeworkId'));
  const attempts = useSelector(attemptsSelectors);
  const attemptsInfo = attempts.info;
  const attemptsBlock = attemptsInfo.el_homework_tasks;
  const load = attempts.load;

  getToken();

  useEffect(() => {
    document.title = 'Спроба | NewBrain';
    setLayout({ page: 'Спроба' });
  }, [setLayout]);

  useEffect(() => {
    if(token) {
      dispatch(getUserInfoThunk({token}));
    }
  }, [token]);

  useEffect(() => {
    if(token) {
      const data = {token, eventId: eventLessonId, homeworkId: homeworkId};
      dispatch(getAttemptsThunk(data));
    }
  }, [token]);

  const goBack = () => {
    navigate('/homework');
    localStorage.removeItem('homeworkId');
  };

  return (
    <div className='attempts container'>
      {load ? <Spinner /> :
      <>
        <Head
          textHead={attemptsInfo.title ? attemptsInfo.title : ''}
          subtitle={`${attemptsInfo?.program_name || ''} - ${attemptsInfo?.lesson_name || ''} - ${attemptsInfo?.homework_block_name || ''}`}
          // subtitleSecond={attemptsInfo.lesson_name ? attemptsInfo.lesson_name : ''}
          goBack={goBack} 
          tooltip={<HeadTooltip text={<AttemptsTooltipHint />} />} 
        />
        <AttemptsContent attemptsBlock={attemptsBlock} attemptsInfo={attemptsInfo} />
      </>}
    </div>
  );
};

export default Attempts;