import React from 'react';
import sprites from '../../img/sprites.svg';
import { courseHint } from './constants';
import './course-hint.scss';

const CourseTooltipHint = () => {
  return (
    <div className='course-hint'>
      {courseHint.map((item, idx) => (
      <div className='course-hint__block' key={idx}>
        <div className='course-hint__icon'>
          <svg className={`icon icon--${item.iconColor} icon--md`}><use xlinkHref={`${sprites}#${item.icon}`}></use></svg>
        </div>
        <div className="course-hint__text">
          <span>-</span>
          {item.text}
        </div>
      </div>
      ))}
    </div>
  );
};

export default CourseTooltipHint;