import types from '../types/index';

const initialState = {
  notifications: {},
  allNotifications: [],
  titleNotifications: '',
  comments: [],
  comment: {},
  modal: false,
  headerModal: false,
  id: 0,
  error: '',
  load: false,
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.notification.GET_NOTIFICATION:
      return {
        ...state, notifications: payload
      };
    case types.notification.GET_ALL_NOTIFACTIONS:
      return {
        ...state, allNotifications: payload
      };
    case types.notification.GET_TITLE_ALL_NOTIFICATIONS:
      return {
        ...state, titleNotifications: payload
      };
    case types.notification.GET_COMMENTS:
      return {
        ...state, comments: payload
      };
    case types.notification.GET_COMMENT:
      return {
        ...state, comment: payload
      };
    case types.notification.GET_NOTIFICATION_ERROR:
      return {
        ...state, error: payload
      };
    case types.notification.NOTIFICATION_COMMENT_ID:
      return {
        ...state, id: payload
      };
    case types.notification.OPEN_NOTIFICATION_MODAL:
      return {
        ...state, modal: true
      };
    case types.notification.CLOSE_NOTIFICATION_MODAL:
      return {
        ...state, modal: false
      };
    case types.notification.OPEN_HEADER_NOTITFICATION_MODAL:
      return {
        ...state, modalHeader: true
      };
    case types.notification.CLOSE_HEADER_NOTITFICATION_MODAL:
      return {
        ...state, modalHeader: false
      };
    case types.notification.START_FETCHING_NOTIFICATION:
      return {
        ...state, load: true
      };
    case types.notification.STOP_FETCHING_NOTIFICATION:
      return {
        ...state, load: false
      };
    default: {
      return state;
    }
  }
};

export default reducer;