import React from 'react';
import PropTypes from 'prop-types';
import ScheduleEvent from '../ScheduleEvent/ScheduleEvent';
import { format } from 'date-fns';
import { uk } from 'date-fns/locale';
import './schedule-day.scss';

const ScheduleDay = ({events, date, setEvents}) => {
  const formattedDate = format(date, 'EEEE, d MMMM', { locale: uk });
  const dayNumber = date.getDate();

  const eventsByDate = events.reduce((acc, event) => {
    const eventDate = new Date(event.date).toLocaleDateString();

    if (!acc[eventDate]) {
      acc[eventDate] = [];
    }
    acc[eventDate].push(event);
    return acc;
  }, {});

  const dayEvents = eventsByDate[new Date(date).toLocaleDateString()] || [];

  return (
    <div className='schedule-day'>
      <div className="schedule-day__num">{dayNumber}</div>
      <div className="schedule-day__content">
        <div className="schedule-day__title">{formattedDate}</div>

        <div className="schedule-day__events">
          {dayEvents.map(event => (
            <ScheduleEvent key={event.id} event={event} setEvents={setEvents} />
          ))}
        </div>
      </div>
    </div>
  );
};

ScheduleDay.propTypes = {
  date: PropTypes.any,
  setEvents: PropTypes.func,
  events: PropTypes.array
};


export default ScheduleDay;