import React, { useContext, useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { LayoutContext } from '../../contexts/layout.context';

import { authSelectors } from '../../store/selectors/auth';
import { getDay, getScheduleType } from '../../store/thunks/schedule';
import { scheduleSelectors } from '../../store/selectors/schedule';
import { getUserInfoThunk } from '../../store/thunks/profile';

import { getToken } from '../../utils/token';
import { getDate } from '../../utils/date';

import HeadTooltip from '../../components/Tooltip/HeadTooltip';
import CourseTooltipHint from '../../components/Tooltip/CourseTooltipHint';
import EventModal from '../../components/schedule/event-modal.component';
import ScheduleMonth from '../../components/schedule/schedule-month.component';

const Schedule = () => {
  const dispatch = useDispatch();
  const dateNow = getDate();
  const location = useLocation();
  
  const type = { value: 'month', label: 'По місяцях' };
  const today = new Date().toLocaleDateString();
  const event = JSON.parse(localStorage.getItem('event'));
  const eventDate = event?.date;
  
  const { setLayout } = useContext(LayoutContext);

  const [dates, setDates] = useState([]);
  const [modalOpen, setModalOpen] = useState('');
  const [events, setEvents] = useState([]);
  const [showMonth, setShowMonth] = useState(dateNow);
  const [selectedDate, setSelectedDate] = useState(eventDate ? eventDate : today);
  const [activeStartDate, setActiveStartDate] = useState(new Date());

  const auth = useSelector(authSelectors);
  const token = auth.token;
  const isFirstRender = useRef(true);

  getToken(token);

  const schedule = useSelector(scheduleSelectors);
  const scheduleType = schedule.scheduleType;
  const scheduleDay = schedule.day;

  useEffect(() => {
    document.title = 'Розклад | NewBrain';
    setLayout({ page: 'Розклад' });
  }, [setLayout]);

  useEffect(() => {
    setDates([]);

    if (isFirstRender?.current) {
      isFirstRender.current = false;
      return;
    }
  
    if (token && showMonth) {
      fetchDates();
    }
  }, [showMonth, token]);

  useEffect(() => {
    if (scheduleType) {
      setDates(scheduleType);
    }
  }, [scheduleType]);

  useEffect(() => {
    if(token) {
      dispatch(getUserInfoThunk({token}));
    }
  }, [token]);

  useEffect(() => {
    if (scheduleDay.length > 0) {
      setEvents(scheduleDay);
    } else {
      setEvents([]);
    }
  }, [scheduleDay]);

  useEffect(() => {
    if (eventDate) {
      const parsedDate = new Date(eventDate);
      setActiveStartDate(new Date(parsedDate.getFullYear(), parsedDate.getMonth(), 1));
      setSelectedDate(parsedDate.toLocaleDateString('uk-UA'));
      setShowMonth(
        new Date(parsedDate.getFullYear(), parsedDate.getMonth(), 1).toLocaleDateString({
          day: '2-digit',
          month: '2-digit',
          year: 'numeric'
        })
      );

      localStorage.removeItem('event');
      localStorage.removeItem('eventLessonId');
    }
  }, [eventDate]);

  useEffect(() => {
    const currentDay = document.querySelector('.choose-day');

    if(currentDay && window.innerWidth < 768) {
      currentDay.scrollIntoView({behavior: 'smooth', block: 'start'});
    } 

    setEventModalHeight();
  }, [selectedDate]);
  
  useEffect(() => {
    const allowedPaths = ['/homework', '/schedule'];
    if (!allowedPaths.some((path) => location.pathname.startsWith(path))) {
      localStorage.removeItem('event');
      localStorage.removeItem('eventLessonId');
    }
  }, [location.pathname]);

  const setEventModalHeight = () => {
    const calendarDays = document.querySelector('.react-calendar__month-view__days');
    if(calendarDays) {
      const height = calendarDays.getBoundingClientRect().height;
      const eventModalBody = document.querySelector('.event-modal__body');

      if(eventModalBody) {
        eventModalBody.style.maxHeight = `${height - 66}px`;
      }
    }
  };

  const fetchDates = () => {
    const url = showMonth ? `?show_month=${showMonth}` : '';
    if (token) {
      dispatch(getScheduleType({ type: type.value, url, token }));
    }
  };

  const handleClickDay = (value) => {
    setModalOpen('open');
    setSelectedDate(new Date(value).toLocaleDateString());
    localStorage.removeItem('event');
    localStorage.removeItem('eventLessonId');
  };

  const fetch = (date) => {
    if (token) {
      dispatch(getDay({ date, token }));
    }
  };

  useEffect(() => {
    if (selectedDate) {
      const [day, month, year] = selectedDate.split('.');
      const formattedSelectedDate = `${year}-${month}-${day}`;
      const selected = new Date(formattedSelectedDate);
      const [showMonthDay, showMonthMonth, showMonthYear] = showMonth.split('.');
      const formattedShowMonth = new Date(showMonthYear, showMonthMonth - 1, showMonthDay); 
      const startOfMonth = new Date(formattedShowMonth.getFullYear(), formattedShowMonth.getMonth(), 1);
      const endOfMonth = new Date(formattedShowMonth.getFullYear(), formattedShowMonth.getMonth() + 1, 0); 

      if ((selected >= startOfMonth && selected <= endOfMonth)) {
        fetch(selectedDate);
      } 
    }
  }, [selectedDate, showMonth, token]);

  return (
    <div className='schedule'>
      <div className="schedule__tooltip">
        <HeadTooltip text={<CourseTooltipHint />} />
      </div>
      <div className="schedule__events">
        <EventModal
          events={events}
          setModalOpen={setModalOpen}
          toggleClass={modalOpen}
          setEvents={setEvents}
        />
      </div>

        {type.value === 'month' && <div className="schedule-month">
          <ScheduleMonth
            dates={dates}
            setShowMonth={setShowMonth}
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            handleClick={handleClickDay} 
            setEvents={setEvents}
            setEventModalHeight={setEventModalHeight}
            activeStartDate={activeStartDate}
            setActiveStartDate={setActiveStartDate}
            showMonth={showMonth}
          />
        </div>}
    </div>
  );
};

export default Schedule;