import React from 'react';
import PropTypes from 'prop-types';
import ButtonIcon from '../../../common/ButtonIcon/ButtonIcon';
import { useLocation, useNavigate } from 'react-router-dom';
import './event-modal-item.scss';
import { homeworkColors } from '../constants';

const EventModalItem = ({event, closeModal}) => { 
  const navigate = useNavigate();
  const location = useLocation();

  const setHomeworkPage = () => {
    localStorage.setItem('event', JSON.stringify(event));
    localStorage.setItem('eventLessonId', JSON.stringify(event.event_lesson_id));
    closeModal();
    navigate('/homework', { state: { from: location.pathname } });
  };

  const setLink = (link) => {
    window.open(link, '_blank');
  };

  return (
    <div className='event-modal-item'>
      <img src={event.image} className="event-modal-item__img" alt={event.direction_short_name} title={event.direction_short_name} />
      <div>
        <div className="event-modal-item__title">{event.direction_short_name}</div>
        <div className="event-modal-item__desc">{event.hour}:00 - {event.hour == 23 ? '00' : Number(event.hour) + 1}:00</div>
        <div className="event-modal-item__desc">Викладач: {event.teacher_name}</div>
      </div>
      <div className="event-modal-item__btns">
        {event.presentation_link && <ButtonIcon 
          classes='event-modal-item__btn' 
          icon='monitor' 
          btnBg='white' 
          iconColor='green' 
          btnSize='lg' 
          iconSize='md' 
          onClick={() => setLink(event.presentation_link)} 
        />}
        {event.vocabulary_link && <ButtonIcon 
          classes='event-modal-item__btn' 
          icon='list' 
          btnBg='white' 
          iconColor='green' 
          btnSize='lg' 
          iconSize='md' 
          onClick={() => setLink(event.vocabulary_link)} 
        />}
        {event.is_show_homework && <ButtonIcon 
          classes='event-modal-item__btn' 
          icon='book' 
          btnBg='white' 
          iconColor={homeworkColors[event.homework_icon_color]} 
          btnSize='lg' 
          iconSize='md' 
          onClick={() => setHomeworkPage()} 
        />}
      </div>
    </div>
  );
};

EventModalItem.propTypes = {
  event: PropTypes.object,
  closeModal: PropTypes.func
};

export default EventModalItem;