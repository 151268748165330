export default {
  GET_TEST: 'GET_TEST',
  TEST_DEFINITIONS: 'TEST_DEFINITIONS',
  TEST_ANSWERS: 'TEST_ANSWERS',
  TEST_TYPE: 'TEST_TYPE',
  CHECK_TEST: 'CHECK_TEST',
  TEST_FINISHED: 'TEST_FINISHED',
  GET_TEST_ERROR: 'GET_TEST_ERROR',
  INFORM_TEST: 'INFORM_TEST',
  GET_INFORM_TEST_ERROR: 'GET_INFORM_TEST_ERROR',
  
  START_FETCHING_TEST_BTN: 'START_FETCHING_TEST_BTN',
  STOP_FETCHING_TEST_BTN: 'STOP_FETCHING_TEST_BTN',
  START_FETCHING_TEST: 'START_FETCHING_TEST',
  STOP_FETCHING_TEST: 'STOP_FETCHING_TEST',
};