import React from 'react';
import PropTypes from 'prop-types';
import './progressbar.scss';

const Progressbar = ({testInfo}) => {
  const progress = (testInfo.el_homework_block_progress / testInfo.el_homework_block_total_questions) * 100;
  return (
    <div className='progressbar'>
      <div className="progressbar__line">
        <span style={{ width: `${progress}%` }}></span>
      </div>
      <div className="progressbar__count">
        {testInfo.el_homework_block_progress}/{testInfo.el_homework_block_total_questions}
        </div>
    </div>
  );
};

Progressbar.propTypes = { 
  testInfo: PropTypes.object
};

export default Progressbar;