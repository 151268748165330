import React from 'react';
import PropTypes from 'prop-types';

import Exit from '../../../Exit/Exit';
import HeaderMobileItem from './HeaderMobileItem';

import { items } from './constants';

import './header-mobile.style.scss';
import HeaderProfile from '../HeaderProfile/HeaderProfile';

const HeaderMobile = ({ setOpenMenu }) => {
  
  const closeModal = () => {
    const notification = document.querySelector('.header-burger');

    if(notification) {
      notification.style.zIndex = 31;
    }

    setOpenMenu(false);
    document.body.classList.remove('open');
  };

  return (
    <>
      <div className='header-mobile'>
        <ul>
          <li>
            {window.innerWidth < 768 && <HeaderProfile />}
          </li>
          {items.map(item => <HeaderMobileItem key={item.title} item={item} onClick={closeModal} />)}
          <li><Exit link='' /></li>
        </ul>
      </div>
      <div className="overlay" onClick={closeModal}></div>
    </>
  );
};

HeaderMobile.propTypes = {
  setOpenMenu: PropTypes.func.isRequired,
};

export default HeaderMobile;