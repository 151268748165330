import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './test-answer.scss';

const TestAnswer = ({answer, count, size, choosen, correct, incorrect, disabled, hidden, ...props}) => {
  const [hideClass, setHideClass] = useState('');

  const answerSize = size ? `test-answer--${size}` : '';
  const choosenClass = choosen ? 'choose' : '';
  const incorrectClass = incorrect ? 'incorrect shake' : '';
  const correctClass = correct ? 'correct' : '';
  const disabledClass = disabled ? 'disabled' : '';

  useEffect(() => {
    if (hidden) {
      const timer = setTimeout(() => {
        setHideClass('hidden');
      }, 1000);
      return () => clearTimeout(timer);
    } else {
      setHideClass('');
    }
  }, [hidden]); 

  const testClasses = [answerSize, choosenClass, incorrectClass, disabledClass, hideClass, correctClass].filter(Boolean).join(' ');

  return (
    <div className={`test-answer ${testClasses}`} {...props}>
      <div className="test-answer__count">{count}</div>
      <div className="test-answer__text">{answer}</div>
    </div>
  );
};

TestAnswer.propTypes = { 
  answer: PropTypes.string,
  size: PropTypes.string,
  count: PropTypes.number,
  choosen: PropTypes.bool,
  correct: PropTypes.bool,
  incorrect: PropTypes.bool,
  disabled: PropTypes.bool,
  hidden: PropTypes.bool
};

export default TestAnswer;