import types from '../types/index';

const initialState = {
  items: [],
  info: {},
  error: '',
  load: false,
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.homework.GET_HOMEWORK_TITLE:
      return {
        ...state, info: payload
      };
    case types.homework.GET_HOMEWORK_BLOCKS:
      return {
        ...state, items: payload
      };
    case types.homework.GET_HOMEWORK_ERROR:
      return {
        ...state, error: payload
      };
    case types.homework.START_FETCHING_HOMEWORK:
      return {
        ...state, load: true
      };
    case types.homework.STOP_FETCHING_HOMEWORK:
      return {
        ...state, load: false
      };
    default: {
      return state;
    }
  }
};

export default reducer;