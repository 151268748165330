export const options = [
  { id: 1, value: 'month', label: 'По місяцях' },
  { id: 2, value: 'day', label: 'По днях' },
];

export const legends = [
  { label: 'Поточний день', class: 'violet' },
  { label: 'Подія', class: 'green' },
  { label: 'Подія запланована на поточний день', class: 'blue' },
];

export const visit = {
  0: 'Не відмічено',
  1: 'Заняття відвідано',
  2: 'Заняття не відвідано',
  3: 'Заняття не відвідано',
  4: 'Заняття не відвідано',
};

export const lessonType = {
  0: 'Заняття',
  1: 'Підвищення кваліфікації',
  2: 'Нарада',
  3: 'Співбесіда',
  4: 'Тест',
  5: 'Відпрацювання пропущеного',
};

export const homeworkColors = {
  1: 'gray',
  2: 'yellow',
  3: 'red',
  4: 'green'
};

export const attendanceColor = {
  0: '',
  1: 'green-event',
  2: 'yellow-event',
  3: 'red-event',
  4: '',
};