
import { getAttempts } from '../../api/attempts/index';
import actions from '../actions/index';

export function getAttemptsThunk(data) {
  return (dispatch) => {
    dispatch(actions.attempts.startFetching());
    getAttempts(data)
      .then(res => {
        if (res.data.info.status) {
          dispatch(actions.attempts.getAttemptsCurrent(res.data.data));
        }
      })
      .catch(error => {
        dispatch(actions.attempts.getAttemptsError(error.response.data?.info?.message));
      })
      .finally(() => dispatch(actions.attempts.stopFetching()));
  };
}

export function getAttemptsCurrentThunk(data) {
  return (dispatch) => {
    dispatch(actions.attempts.startFetchingAnswers());
    getAttempts(data)
      .then(res => {
        if (res.data.info.status) {
          dispatch(actions.attempts.getAttemptsCurrent(res.data.data));
        }
      })
      .catch(error => {
        dispatch(actions.attempts.getAttemptsError(error.response.data?.info?.message));
      })
      .finally(() => dispatch(actions.attempts.stopFetchingAnsswers()));
  };
}