import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import sprite from '../../../../img/sprites.svg';

const HeaderProfile = ({link}) => {
  const user = JSON.parse(localStorage.getItem('user'));

  return (
    <NavLink to='/profile' className="header-user">
      <svg className="icon"><use href={`${sprite}#user`}></use></svg>
      <div className={`header-user__info ${link ? 'link' : ''}`}>{user?.first_name} {user?.last_name}</div>
    </NavLink>
  );
};

HeaderProfile.propTypes = {
  link: PropTypes.bool,
};

export default HeaderProfile;