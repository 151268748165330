import React from 'react';
import PropTypes from 'prop-types';
import './attempts-answer-block.scss';

const AttemptsAnswerBlock = ({title, text, margin, questionClass, translateAnswers}) => {
  const classes = [margin, questionClass].filter(Boolean).join(' ');
  return (
    <div className={`attempts-answer-block ${classes}`}>
      <span className='attempts-answer-block__title'>{title}</span>
      {translateAnswers 
      ? translateAnswers.map((answer, idx) => (
        <div className='attempts-answer-block__item' key={idx}>
          <div className='attempts-answer-block__number'>{idx + 1}.</div>
          <span className='attempts-answer-block__question'>
            {answer}
          </span>
        </div>
      ))
      : <span className='attempts-answer-block__question'>
        {text}
      </span>}
  </div>
  );
};

AttemptsAnswerBlock.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  margin: PropTypes.string,
  questionClass: PropTypes.string,
  translateAnswers: PropTypes.array
};

export default AttemptsAnswerBlock;