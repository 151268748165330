import React from 'react';
import PropTypes from 'prop-types';
import sprite from '../../img/sprites.svg';
import './button-icon.scss';

const ButtonIcon = ({ icon, btnBg, iconSize, iconColor, classes, btnSize, ...props }) => {
  const bg = btnBg ? `btn-icon--${btnBg}` : '';
  const size = btnSize ? `btn-icon--${btnSize}` : '';
  const sizeIcon = iconSize ? `icon--${iconSize}` : '';
  const colorIcon = iconColor ? `icon--${iconColor}` : '';
  const btnClasses = [classes, bg, size].filter(Boolean).join(' ');
  const iconClasses = [sizeIcon, colorIcon].filter(Boolean).join(' ');

  return (
    <button className={`btn-icon ${btnClasses}`} {...props}>
      <svg className={`icon ${iconClasses}`}><use href={`${sprite}#${icon}`}></use></svg>
    </button> 
  );
};

ButtonIcon.propTypes = {
  icon: PropTypes.string.isRequired,
  classes: PropTypes.string,
  btnBg: PropTypes.string,
  iconSize: PropTypes.string,
  iconColor: PropTypes.string,
  btnSize: PropTypes.string
};

export default ButtonIcon;