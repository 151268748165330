import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import actions from '../../store/actions';
import { getUserInfoThunk } from '../../store/thunks/profile';
import { getNotificationThunk } from '../../store/thunks/notification';
import ButtonIcon from '../../common/ButtonIcon/ButtonIcon';
import AttemptsAnswerBlock from './AttemptsAnswerBlock';
import './attempts-answer.scss';

const AttemptsAnswer = ({block, token, info}) => {
  const dispatch = useDispatch();
  const color = block?.el_homework_answers[0]?.is_correct ? 'correct' : 'incorrect';
  const unread = block?.is_unread_notification ? 'counter' : '';
  const questionClass = info?.homework_block_type === 'conformity' ? 'attempts-answer-block--definition' : '';

  const setNotificationModal = (token, id) => {
    const data = {token, id};
    dispatch(getNotificationThunk(data));
    dispatch(getUserInfoThunk({token}));
    dispatch(actions.notification.notificationCommentId(id));
    dispatch(actions.notification.openNotificationModal());
    dispatch(actions.notification.getNotificationError(''));
  };

  return (
    <div className={`attempts-answer ${block.el_homework_answers.length > 0 ? color : ''}`}>
      <div className="attempts-answer__title">Питання {block.number}</div>

      {block.el_homework_answers.length > 0 &&
        <div className={`attempts-answer__question ${unread}`}>
          <ButtonIcon 
            icon='message-question' 
            iconColor='violet' 
            btnSize='md' 
            classes='attempts-answer__icon' 
            onClick={() => setNotificationModal(token, block?.el_homework_task_id)} 
          />
        </div>
      }

      {block?.number && <div className='attempts-answer__count'>{block.number}.</div>}

      <AttemptsAnswerBlock title='Питання' text={block.task_name} questionClass={questionClass} />

      {info?.homework_block_type === 'test' && <>
        {block?.el_homework_answers.map((answer, idx) => (
          <div className='attempts-answer__answers' key={idx}>
            <AttemptsAnswerBlock title='Відповідь' text={answer.answer} />
            <AttemptsAnswerBlock title='Правильна відповідь' text={answer.correct_answer} />
          </div>
        ))}
      </>}

      {info?.homework_block_type === 'conformity' && <>
        {block?.el_homework_answers.map((answer, idx) => (
          <div className='attempts-answer__answers attempts-answer__answers--2' key={idx}>
            <AttemptsAnswerBlock title='Відповідність' text={answer.answer} margin='mb-0' />
            <AttemptsAnswerBlock text={answer.definition} margin='mb-0' />
          </div>
        ))}
      </>}

      {info?.homework_block_type === 'translate' && <>
        {block?.el_homework_answers.map((answer, idx) => (
          <div className='attempts-answer__answers attempts-answer__answers--2' key={idx}>
            <AttemptsAnswerBlock title='Відповідь' text={answer.answer} margin='mb-0' />
            {answer?.translate_correct_answers.length > 0
            && <AttemptsAnswerBlock title='Правильні відповіді' translateAnswers={answer.translate_correct_answers} margin='mb-0'/>}
          </div>
        ))}
      </>}
    </div>
  );
};

AttemptsAnswer.propTypes = {
  block: PropTypes.object,
  token: PropTypes.string,
  info: PropTypes.object
};

export default AttemptsAnswer;