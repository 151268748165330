import React from 'react';
import PropTypes from 'prop-types';
import { statuColor, statuIcon, statusText } from './constants';
import sprite from '../../img/sprites.svg';
import './attempts-info.scss';

const AttemptsInfo = ({activeTab, toggleTab, attemptsInfo}) => {
  return (
    <div className='attempts-info'>
      <div className="attempts-info__title">{attemptsInfo.description}</div>
      <div className="attempts-info__block">
        <span>Статус</span>
        <div className="attempts-info__status">
          {attemptsInfo.homework_icon_color < 4 &&
          <svg className={`icon icon--${statuColor[attemptsInfo.homework_icon_color]}`}>
            <use href={`${sprite}#${statuIcon[attemptsInfo.homework_icon_color]}`}></use>
          </svg>}
          <div className='attempts-info__status-text'>{statusText[attemptsInfo.homework_icon_color]}</div>
          {attemptsInfo.homework_icon_color === 4 &&
          <svg className='icon icon--green'>
            <use href={`${sprite}#${statuIcon[attemptsInfo.homework_icon_color]}`}></use>
          </svg>}
        </div>
      </div>
      <div className="attempts-info__block">
        <span>Пройдено питань</span>
        <span>{attemptsInfo.answered_questions} / {attemptsInfo.total_questions}</span>
      </div>
      <div className="attempts-info__block">
        <span>Правильні відповіді</span>
        <span>{attemptsInfo.correct_answered_questions} / {attemptsInfo.total_questions}</span>
      </div>
      {attemptsInfo?.el_homework_blocks && window.location.pathname === '/homework/reviews' 
        && attemptsInfo.el_homework_blocks.map((btn, idx) => (
        <button 
          type='button' 
          className={`attempts-info__tab ${activeTab === idx ? 'active' : ''}`} 
          key={btn.button_name}
          onClick={() => (toggleTab(btn.el_homework_block_id, idx))}
        >
          {btn.button_name}
        </button>
      ))}
    </div>
  );
};

AttemptsInfo.propTypes = {
  activeTab: PropTypes.number,
  setActiveTab: PropTypes.func,
  toggleTab: PropTypes.func,
  attemptsInfo: PropTypes.object,
};

export default AttemptsInfo;