import React, { useContext, useEffect, useState } from 'react';
import { LayoutContext } from '../../contexts/layout.context';
import { useLocation, useNavigate } from 'react-router-dom';
import Head from '../../components/Head/Head';
import HomeworkCard from '../../components/Homework/HomeworkCard';
import HeadTooltip from '../../components/Tooltip/HeadTooltip';
import HomeworkTooltipHint from '../../components/Tooltip/HomeworkTooltipHint';
import { useDispatch, useSelector } from 'react-redux';
import { homeworkSelectors } from '../../store/selectors/homework';
import { authSelectors } from '../../store/selectors/auth';
import { getToken } from '../../utils/token';
import { getHomeworkThunk } from '../../store/thunks/homework';
import { homeworkColors } from '../../components/Homework/constants';
import { getUserInfoThunk } from '../../store/thunks/profile';
import Spinner from '../../common/Spinner/Spinner';

const Homework = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { setLayout } = useContext(LayoutContext);

  const savedAccordion = sessionStorage.getItem('openAccordion');
  const eventLessonId = JSON.parse(localStorage.getItem('eventLessonId'));
  const auth = useSelector(authSelectors);
  const token = auth.token;
  const homework = useSelector(homeworkSelectors);
  const homeworkItems = homework.items;
  const homeworkInfo = homework.info;
  const load = homework.load;

  const [openAccordion, setOpenAccordion] = useState(null);

  getToken(token);
 
  useEffect(() => {
    document.title = 'Домашнє завдання | NewBrain';
    setLayout({ page: 'Домашнє завдання' });
  }, [setLayout]);

  useEffect(() => {
    if(token) {
      dispatch(getUserInfoThunk({token}));
    }
  }, [token]);

  useEffect(() => {
    if(eventLessonId && token) {
      const data = {id: eventLessonId, token};
      dispatch(getHomeworkThunk(data)); 
    }
  }, [token, eventLessonId]);

  useEffect(() => {
    if(!load) {
      if(savedAccordion) {
        setOpenAccordion(Number(savedAccordion));
      } else {
        setOpenAccordion(homeworkItems[0]?.id);
      }
    }
  }, [homeworkItems, load, savedAccordion]);

  useEffect(() => {
    if (savedAccordion) {
      setOpenAccordion(savedAccordion);
    }
  }, []); 
 
  useEffect(() => {
    if (location.state?.from && !sessionStorage.getItem('initialRoute')) {
      sessionStorage.setItem('initialRoute', location.state.from);
    }
  }, [location.state]);

  
  const goBack = () => { 
    const initialRoute = sessionStorage.getItem('initialRoute');
  
    if (initialRoute) {
      navigate(initialRoute);
    } else {
      navigate('/schedule');
    }
    
    sessionStorage.removeItem('initialRoute');
    sessionStorage.removeItem('openAccordion');
  };
  
  
  const toggleAccordion = (id) => {
    const newOpen = openAccordion === id ? null : id;
    setOpenAccordion(newOpen);
    sessionStorage.setItem('openAccordion', newOpen);
  };
  

  return (
    <div className='homework container'>
      <Head 
        textHead={homeworkInfo.title ? homeworkInfo.title : ''} 
        subtitle={homeworkInfo.program_name ? homeworkInfo.program_name : ''}
        subtitleSecond={homeworkInfo.lesson_name ? homeworkInfo.lesson_name : ''}
        goBack={goBack} 
        tooltip={<HeadTooltip text={<HomeworkTooltipHint />} />} 
      />
      {load ? <Spinner /> : <>
        <div className="homework__cards">
          {homeworkItems.map(((homework, idx) => <HomeworkCard 
          key={idx}
          count={idx + 1}
          id={homework.id}
          title={homework.title}
          color={homeworkColors[homework.homework_icon_color]}
          description={homework.description}
          testsCount={homework.total_questions}
          attempts={homework.max_iteration}
          toggleAccordion={toggleAccordion}
          isOpen={openAccordion === homework.id}
          isContinue={homework.max_iteration > 0}
          load={load}
          continueBtn={homework.is_show_continue_button}
          homeworkElId={homeworkInfo.el_homework_id}
          homeworkBlockId={homework.el_homework_block_id}
          token={token}
          />))}
        </div>
      </>}
    </div>
  );
};

export default Homework;