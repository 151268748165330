import { checkTest, continueTest, informTest, startTest } from '../../api/test/index';
import actions from '../actions/index';

export function getTestThunk(data) {
  return (dispatch) => {
    dispatch(actions.test.startFetching());
    startTest(data)
      .then(res => {
        if (res.data.info.status) {
          dispatch(actions.test.getTest(res.data.data));
          dispatch(actions.test.getTestAnswers(res.data.data.homework_task_answers));
          dispatch(actions.test.getTestDefinitions(res.data.data.homework_task_definitions));
          dispatch(actions.test.getTestType(res.data.data.homework_block_type));
        }
      })
      .catch(error => {
        dispatch(actions.test.getTestError(error.response.data?.info?.message));
      })
      .finally(() => dispatch(actions.test.stopFetching()));
  };
}

export function continueTestThunk(data) {
  return (dispatch) => {
    dispatch(actions.test.startFetchingBtn());
    continueTest(data)
      .then(res => {
        if (res.data.info.status) {
          if(res.data.data.is_finished) {
            dispatch(actions.test.finishedTest(res.data.data));
          } else {
            dispatch(actions.test.getTest(res.data.data));
            dispatch(actions.test.getTestAnswers(res.data.data.homework_task_answers));
            dispatch(actions.test.getTestDefinitions(res.data.data.homework_task_definitions));
            dispatch(actions.test.getTestType(res.data.data.homework_block_type));
          }
        }
      })
      .catch(error => {
        dispatch(actions.test.getTestError(error.response.data?.info?.message));
      })
      .finally(() => dispatch(actions.test.stopFetchingBtn()));
  };
}

export function checkTestThunk(data) {
  return (dispatch) => {
    dispatch(actions.test.startFetchingBtn());
    checkTest(data)
      .then(res => {
        if (res.data.info.status) {
          dispatch(actions.test.checkTest(res.data.data));
        }
      })
      .catch(error => {
        dispatch(actions.test.getTestError(error.response.data?.info?.message));
      })
      .finally(() => dispatch(actions.test.stopFetchingBtn()));
  };
}

export function informTestThunk(data) {
  return (dispatch) => {
    informTest(data)
      .then(res => {
        if (res.data.info.status) {
          dispatch(actions.test.informTest(res.data.data.text));
        }
      })
      .catch(error => {
        dispatch(actions.test.informTestError(error.response.data?.info?.message));
      });
  };
}