import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { authSelectors } from '../../store/selectors/auth';
import sprite from '../../img/sprites.svg';
import FieldTextarea from '../../common/Fields/FieldTextarea';
import { notificationSelectors } from '../../store/selectors/notification';
import { sendNotificationThunk } from '../../store/thunks/notification';
import ErrorField from '../../common/Errors/ErrorField';
import actions from '../../store/actions';

const NotificationFooter = () => {
  const dispatch = useDispatch();
  const auth = useSelector(authSelectors);
  const token = auth.token;
  const notification = useSelector(notificationSelectors);
  const commentId = notification.id;
  const error = notification.error;
  
  const [commentVal, setCommentVal] = useState('');

  const sendComment = (token, id, comment) => {
    const data = {token, id, comment};
    dispatch(sendNotificationThunk(data));
    dispatch(actions.notification.getNotificationError(''));
    setCommentVal('');
  };

  return (
    <>
      <div className='notification__field'>
        <FieldTextarea 
          id='comment' 
          name="comment" 
          value={commentVal} 
          placeholder='Коментар' 
          onChange={(e) => setCommentVal(e.target.value)} 
          formClass="" 
        />
        {error && <ErrorField errorText={error} />}
      </div>
      <button className="notification__send" onClick={() => sendComment(token, commentId, commentVal)}>
        <span>Відправити</span>
        <svg className="icon icon--white icon--md"><use href={`${sprite}#send`}></use></svg>
      </button>
    </>
  );
};

export default NotificationFooter;