import api, { setToken } from '../index';

export function startTest(data) {
  return (api.get(`/schedule/homework/${data.homeworkId}/block/${data.blockId}/start`, setToken(data.token)));
}

export function continueTest(data) {
  return (api.get(`/schedule/homework/block/${data.id}/continue`, setToken(data.token)));
}

export function checkTest(data) {
  return (api.post(`/schedule/homework/block/${data.blockId}/task/${data.taskId}/check`, data.data, setToken(data.token)));
}

export function informTest(data) {
  return (api.post(`/schedule/homework/${data.homeworkId}/task/${data.taskId}/error/send`, {error_text: data.text}, setToken(data.token)));
}
