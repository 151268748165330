import React from 'react';
import PropTypes from 'prop-types';
import './tooltip.style.scss';

const Tooltip = ({text, showTooltip, hoverTooltip, children, ...props}) => {
  return (
    <div className={`tooltip ${showTooltip ? 'show' : ''} ${hoverTooltip ? 'tooltip--hover' : ''}`} {...props}>
      <div className="tooltip__content">
      {children}
      </div>
      <span className="tooltip__text">{text}</span>
  </div>
  );
};

Tooltip.propTypes = {
  children: PropTypes.any,
  text: PropTypes.any,
  showTooltip: PropTypes.bool,
  hoverTooltip: PropTypes.bool,
};

export default Tooltip;