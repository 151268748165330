import React, { useEffect, useState } from 'react';

import HeaderNav from './HeaderNav/HeaderNav';
import HeaderActions from './HeaderActions/HeaderActions';
import HeaderMobile from './HeaderActions/HeaderMobile/HeaderMobile';

const Header = () => {
  const token = JSON.parse(localStorage.getItem('token'));

  const [openMenu, setOpenMenu] = useState(false);
  
  const openClass = openMenu ? 'open' : '';
  const classes = [openClass].filter(Boolean).join(' ');

  useEffect(() => {
    const notification = document.querySelector('.header-actions__notification');
    const burger = document.querySelector('.header-burger');

    if(burger) {
      burger.style.zIndex = 31;
    }

    if(notification) {
      notification.style.zIndex = 10;
    }
  }, [openClass]);

  return (
    <header className={`header ${classes}`}>

      <nav>
        <HeaderActions setOpenMenu={setOpenMenu} token={token} />
        {token &&
          <>
            <HeaderNav />
            <HeaderMobile setOpenMenu={setOpenMenu} />
          </>
        }
      </nav>

  </header>
  );
};

export default Header;