import React, { useState } from 'react';
import PropTypes from 'prop-types';
import AttemptsAnswer from './AttemptsAnswer';
import AttemptsInfo from './AttemptsInfo';
import { useDispatch, useSelector } from 'react-redux';
import { authSelectors } from '../../store/selectors/auth';
import { getAttemptsCurrentThunk } from '../../store/thunks/attempts';

const AttemptsContent = ({attemptsInfo, attemptsBlock}) => {
  const dispatch = useDispatch();

  const [activeTab, setActiveTab] = useState(0);

  const auth = useSelector(authSelectors);
  const token = auth.token;
  const eventLessonId = JSON.parse(localStorage.getItem('eventLessonId'));
  const homeworkId = JSON.parse(localStorage.getItem('homeworkId'));

  const toggleTab = (id, idx) => {
    setActiveTab(idx);
    const data = {token, eventId: eventLessonId, homeworkId: homeworkId, homeworkBlockId: id};
    dispatch(getAttemptsCurrentThunk(data));
  };

  return (
    <div className="attempts-content">
      <AttemptsInfo activeTab={activeTab} setActiveTab={setActiveTab} toggleTab={toggleTab} attemptsInfo={attemptsInfo} />

      <div className="attempts-answers">
        <div className="attempts-answers__head">
          <div className="attempts-answers__title">№</div>
          <div className="attempts-answers__title">Питання</div>
          {attemptsInfo?.homework_block_type === 'conformity' ? 
            <div className="attempts-answers__title">Відповідність</div>
          : <>
            <div className="attempts-answers__title">Відповідь</div>
            <div className="attempts-answers__title">
              {attemptsInfo.homework_block_type === 'translate' ? 'Правильні відповіді' : 'Правильна відповідь'}
            </div>
          </>}
        </div>

        {attemptsBlock?.length > 0 && attemptsBlock.map((block, idx) => (
          <AttemptsAnswer key={idx} block={block} token={token} info={attemptsInfo} />
        ))}
      </div>
    </div>
  );
};

AttemptsContent.propTypes = {
  attemptsInfo: PropTypes.object,
  attemptsBlock: PropTypes.array,
};

export default AttemptsContent;