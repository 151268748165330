import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Tooltip from './Tooltip';
import ButtonIcon from '../../common/ButtonIcon/ButtonIcon';
import './head-tooltip.scss';

const HeadTooltip = ({text}) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const tooltipRef = useRef(null); 

  const handleClick = () => {
    if(window.innerWidth < 768) {
      setShowTooltip((prev) => !prev);
    }
  };

  const handleOutsideClick = (event) => {
    if (
      tooltipRef.current &&
      !tooltipRef.current.contains(event.target)
    ) {
      setShowTooltip(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick);
    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  return (
    <div ref={tooltipRef} className='head-tooltip'>
      <Tooltip 
        showTooltip={showTooltip} 
        hoverTooltip={true} 
        text={text} 
        onClick={() => handleClick()}
      >
        <ButtonIcon icon='question' iconColor='violet' />
      </Tooltip>
    </div>
  );
};


HeadTooltip.propTypes = {
  text: PropTypes.any,
};

export default HeadTooltip;