
import { getHomework } from '../../api/homework/index';
import actions from '../actions/index';

export function getHomeworkThunk(data) {
  return (dispatch) => {
    dispatch(actions.homework.startFetching());
    getHomework(data)
      .then(res => {
        if (res.data.info.status) {
          dispatch(actions.homework.getHomeworkTitle(res.data.data));
          dispatch(actions.homework.getHomeworkBlocks(res.data.data.homework_blocks));
        }
      })
      .catch(error => {
        dispatch(actions.homework.getHomeworkError(error.response.data?.info?.message));
      })
      .finally(() => dispatch(actions.homework.stopFetching()));
  };
}