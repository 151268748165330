import { birthDate, changesVerified, checkChanges, code, edit, phoneAgain, profile, telegram, user } from '../../api/profile/index';
import actions from '../actions/index';

// for profile
export function getProfileThunk(data) {
  return (dispatch) => {
    dispatch(actions.profile.startFetching());
    profile(data)
      .then(res => {
        if (res.data.info.status) {
          dispatch(actions.profile.userInfo(res.data.data.user));
          localStorage.setItem('user', JSON.stringify(res.data.data.user));

          dispatch(actions.profile.userConfirmInfo(res.data.data.confirmationInformations));
          localStorage.setItem('confirmInfo', JSON.stringify(res.data.data.confirmationInformations));

          dispatch(actions.profile.setTimesToLesson(res.data.data.times));
          localStorage.setItem('times', JSON.stringify(res.data.data.times));
        }
      })
      .catch(error => {
        dispatch(actions.profile.profileError(error.response.data.data));
      })
      .finally(() => dispatch(actions.profile.stopFetching()));
  };
}

// for another pages
export function getUserInfoThunk(data) {
  return (dispatch) => {
    dispatch(actions.profile.startFetching());
    user(data)
      .then(res => {
        if (res.data.info.status) {
          dispatch(actions.profile.userInfo(res.data.data.user));
          localStorage.setItem('user', JSON.stringify(res.data.data.user));
          dispatch(actions.profile.contacts(res.data.data.contacts));
        }
      })
      .catch(error => {
        dispatch(actions.profile.profileError(error.response.data.data));
      })
      .finally(() => dispatch(actions.profile.stopFetching()));
  };
}


export function editUserInfoThunk(data) {
  return (dispatch) => {
    dispatch(actions.profile.startFetching());
    edit(data)
      .then(res => {
        if (res.data.info.status) {
          dispatch(actions.profile.editUserInfo(true));

          if(res.data.data.phone) {
            dispatch(actions.profile.editUserPhone(res.data.data.phone));
            localStorage.setItem('phone', JSON.stringify(res.data.data.phone));
          }
        }
      })
      .catch(error => {
        dispatch(actions.profile.profileError(error.response.data.data));
      })
      .finally(() => dispatch(actions.profile.stopFetching()));
  };
}

export function getCodeAgainThunk(data) {
  return (dispatch) => {
    phoneAgain(data)
      .then(res => {
        if (res.data.info.status) {
          dispatch(actions.profile.editUserPhone(res.data.data.phone));
          localStorage.setItem('phone', JSON.stringify(res.data.data.phone));
        }
      })
      .catch(error => {
        dispatch(actions.profile.profileError(error.response.data.data));
      });
  };
}

export function editPhoneCodeThunk(data) {
  return (dispatch) => {
    dispatch(actions.profile.startFetching());
    code(data)
      .then(res => {
        if (res.data.info.status) {
          dispatch(actions.profile.editUserInfo(true));
        }
      })
      .catch(error => {
        dispatch(actions.profile.profileError(error.response.data.data));
      })
      .finally(() => dispatch(actions.profile.stopFetching()));
  };
}

export function checkChangedDataThunk(data) {
  return (dispatch) => {
    checkChanges(data)
      .then(res => {
        if (res.data.info.status) {
          dispatch(actions.profile.checkChangedData(res.data.data));
        }
      })
      .catch(error => {
        dispatch(actions.profile.profileError(error.response.data.data));
      });
  };
}

export function changedDataVerifiedThunk(data) {
  return (dispatch) => {
    changesVerified(data)
      .then(res => {
        if (res.data.info.status) {
          dispatch(actions.profile.changedDataVerified(true));
        }
      })
      .catch(error => {
        dispatch(actions.profile.profileError(error.response.data.data));
      });
  };
}

export function setTelegramThunk(data) {
  return (dispatch) => {
    telegram(data)
      .then(res => {
        if (res.data.info.status) {
          dispatch(actions.profile.telegram(res.data.data));
        }
      })
      .catch(error => {
        dispatch(actions.profile.profileError(error.response.data.data));
      });
  };
}

export function setBirthDateThunk(data) {
  return (dispatch) => {
    birthDate(data)
      .then(res => {
        if (res.data.info.status) {
          dispatch(actions.profile.birthDate(res.data.data));
        }
      })
      .catch(error => {
        dispatch(actions.profile.profileError(error.response.data.data));
      });
  };
}