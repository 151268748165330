import React, { useState } from 'react';
import PropTypes from 'prop-types';
import FieldTextarea from '../../common/Fields/FieldTextarea';
import { useDispatch, useSelector } from 'react-redux';
import { testSelectors } from '../../store/selectors/test';
import Button from '../../common/Buttons/Button';
import ErrorField from '../../common/Errors/ErrorField';
import actions from '../../store/actions';
import { checkTestThunk, continueTestThunk } from '../../store/thunks/test';
import { authSelectors } from '../../store/selectors/auth';
import './hint-test.scss';

const TestTranslate = ({setOpenModal}) => {
  const dispatch = useDispatch();

  const auth = useSelector(authSelectors);
  const token = auth.token;
  const test = useSelector(testSelectors);
  const testInfo = test.test;
  const loadBtn = test.loadBtn;
  const error = test.error;
  const check = test.check;

  const [answerVal, setAnswerVal] = useState('');
  const [isAnswered, setIsAnswered] = useState(false);

  const handleCheckAnswer = (blockId, taskId, token) => {
    if (isAnswered && !error) return;

    const data = {blockId, taskId, data: {answer_text: answerVal}, token};
    dispatch(actions.test.getTestError(null));
    dispatch(actions.test.checkTest({}));
    dispatch(actions.test.getTestError(''));
    dispatch(checkTestThunk(data));
    setIsAnswered(true);
  };

  const setNextTest = (id, token) => {
    const data = {id, token};
    setIsAnswered(false);
    setAnswerVal('');
    dispatch(actions.test.checkTest({}));
    dispatch(actions.test.getTestError(''));
    localStorage.setItem('currentTestId', JSON.stringify(id));
    dispatch(continueTestThunk(data));
  };

  const setTestModal = () => {
    setOpenModal(true);
  };

  return (
    <>
      <div className="test__field">
        <FieldTextarea        
          id='answer' 
          name="answer" 
          value={answerVal} 
          placeholder='Введіть вашу відповідь' 
          onChange={(e) => setAnswerVal(e.target.value)} 
          disabled={Object.keys(check)?.length ? true : false}
          formClass={check?.is_correct === false ? 'incorrect shake' : check?.is_correct === true ? 'correct blink' : ''}  
        />
        {error &&  <ErrorField errorText={error} />}

        {Object.keys(check)?.length > 0 && check?.is_correct 
          ? <span className="success-field">{check.correct_text}</span>
          :  <ErrorField errorText={check.correct_text} />}

        {Object.keys(check)?.length > 0 && check?.correct_options?.length > 0 ? 
        <div className="test__hint hint-test">
          <div className="hint-test__title">{check.correct_options_text}</div>
          {check?.correct_options.length > 0 && check?.correct_options.map((item, idx) => (
            <div key={idx} className="hint-test__item">{idx + 1}. {item}</div>
          ))}
        </div> : ''}
      </div> 
      <div className="test__btns">
        {isAnswered && !error
        ? <Button buttonBg='green' textSize='md' btnRadius='md' disabled={loadBtn ? true : false}
            onClick={() => setNextTest(testInfo.el_homework_block_id, token)}
          >
            Далі
          </Button>
        : <Button buttonBg='green' textSize='md' btnRadius='md' disabled={loadBtn ? true : false}
            onClick={() => handleCheckAnswer(testInfo.el_homework_block_id, testInfo.homework_task_id, token)}
          >
            Перевірити
          </Button>}
        <Button buttonBg='light' iconSvg='flag' iconColor='violet' onClick={() => setTestModal()}>
          Повідомити про помилку
        </Button>
      </div>
    </>
  );
};

TestTranslate.propTypes = {
  setOpenModal: PropTypes.func,
};


export default TestTranslate;