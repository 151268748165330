import api, { setToken } from '../index';

export function getNotification(data) {
  return (api.get(`/schedule/homework/task/${data.id}/comments`, setToken(data.token)));
}

export function postNotification(data) {
  return (api.post(`/schedule/homework/${data.id}/comments/send`, {comment: data.comment}, setToken(data.token)));
}

export function getAllNotifications(data) {
  return (api.get('/schedule/homework/comments', setToken(data.token)));
}