import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import './notification-message.scss';

const NotificationMessage = ({last, comment, setLastMessage, sendText}) => {
  const lastEl = useRef();

  useEffect(() => {
    if(lastEl) {
      setLastMessage(lastEl);
    }
  }, [lastEl, comment]);

  return (
    <div>
      <div className={`notification-message ${comment.user_type === 'Учень' ? 'send' : ''}`} ref={last ? lastEl : null}>
        <div className="notification-message__name">
          <span>{comment.user_name}</span> 
          <span>{comment.user_type}</span>
        </div>
        <div className="notification-message__time">{comment.date}</div>
        <div className="notification-message__text">
          {comment.comment}
        </div>
      </div>
      {(last && sendText) && <span className="notification-label">{sendText}</span>}
    </div>
  );
};

NotificationMessage.propTypes = {
  last: PropTypes.bool,
  comment: PropTypes.object,
  setLastMessage: PropTypes.func,
  sendText: PropTypes.string,
};

export default NotificationMessage;