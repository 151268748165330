import types from '../types/index';

const initialState = {
  test: {},
  answers: [],
  definitions: [],
  type: '',
  check: {},
  finished: {},
  inform: '',
  informError: '',
  error: '',
  load: false,
  loadBtn: false,
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.test.GET_TEST:
      return {
        ...state, test: payload
      };
    case types.test.TEST_ANSWERS:
      return {
        ...state, answers: payload
      };
    case types.test.TEST_DEFINITIONS:
      return {
        ...state, definitions: payload
      };
    case types.test.TEST_TYPE:
      return {
        ...state, type: payload
      };
    case types.test.CHECK_TEST:
      return {
        ...state, check: payload
      };
    case types.test.TEST_FINISHED:
      return {
        ...state, finished: payload
      };
    case types.test.INFORM_TEST:
      return {
        ...state, inform: payload
      };
    case types.test.GET_INFORM_TEST_ERROR:
      return {
        ...state, informError: payload
      };
    case types.test.GET_TEST_ERROR:
        return {
          ...state, error: payload
        };
    case types.test.START_FETCHING_TEST:
      return {
        ...state, load: true
      };
    case types.test.STOP_FETCHING_TEST:
      return {
        ...state, load: false
      };
    case types.test.START_FETCHING_TEST_BTN:
      return {
        ...state, loadBtn: true
      };
    case types.test.STOP_FETCHING_TEST_BTN:
      return {
        ...state, loadBtn: false
      };
    default: {
      return state;
    }
  }
};

export default reducer;