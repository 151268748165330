export default {
  OPEN_NOTIFICATION_MODAL: 'OPEN_NOTIFICATION_MODAL',
  CLOSE_NOTIFICATION_MODAL: 'CLOSE_NOTIFICATION_MODAL',
  GET_NOTIFICATION: 'GET_NOTIFICATION',
  GET_COMMENTS: 'GET_COMMENTS',
  GET_COMMENT: 'GET_COMMENT',
  GET_NOTIFICATION_ERROR: 'GET_NOTIFICATION_ERROR',
  NOTIFICATION_COMMENT_ID: 'NOTIFICATION_COMMENT_ID',
  GET_ALL_NOTIFACTIONS: 'GET_ALL_NOTIFACTIONS',
  GET_TITLE_ALL_NOTIFICATIONS: 'GET_TITLE_ALL_NOTIFICATIONS',
  OPEN_HEADER_NOTITFICATION_MODAL: 'OPEN_HEADER_NOTITFICATION_MODAL',
  CLOSE_HEADER_NOTITFICATION_MODAL: 'CLOSE_HEADER_NOTITFICATION_MODAL',

  START_FETCHING_NOTIFICATION: 'START_FETCHING_NOTIFICATION',
  STOP_FETCHING_NOTIFICATION: 'STOP_FETCHING_NOTIFICATION',
};