import React from 'react';
import { homeworkHint } from './constants';
import './homework-hint.scss';

const HomeworkTooltipHint = () => {
  return (
    <div className='homework-hint'>
      {homeworkHint.map((item, idx) => (
      <div className='homework-hint__block' key={idx}>
        <div className={`homework-hint__text homework-hint__text--${item.lineColor}`}>
          <span>-</span>
          {item.text}
        </div>
      </div>
      ))}
    </div>
  );
};

export default HomeworkTooltipHint;