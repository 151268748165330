import React from 'react';
import PropTypes from 'prop-types';
import ButtonIcon from '../../common/ButtonIcon/ButtonIcon';
import './notification-head.scss';

const NotificationHead = ({closeModal, title}) => {
  return (
    <div className="notification-head">
      <div className="notification-head__title">{title}</div>
      <ButtonIcon icon="close" classes='notification-head__close' iconColor='violet' onClick={() => closeModal()} />
    </div>
  );
};

NotificationHead.propTypes = {
  closeModal: PropTypes.func,
  title: PropTypes.string,
};

export default NotificationHead;