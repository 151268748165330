import React from 'react';
import PropTypes from 'prop-types';
import sprites from '../../img/sprites.svg';
import './notification-item.scss';

const NotificationHeaderItem = ({notification, ...props}) => {
  const unread = notification.not_read_comment_count > 0 ? 'unread' : '';
  return (
    <div className={`notification-item ${unread}`} key={notification.el_homework_task_id} {...props}>
      <svg className="icon icon--violet icon--md"><use xlinkHref={`${sprites}#message-text`}></use></svg>
      <div className="notification-item__content">
        <div className="notification-item__name">
          <span>{notification.user_name}</span>
          <span>{notification.user_type}</span>
        </div>
        <div className="notification-item__text">
          {notification.last_comment_comment}
        </div>
        <div className="notification-item__date">
          {notification.last_comment_date}
        </div>
      </div>
      {unread &&  
        <div className="notification-item__count">{notification.not_read_comment_count}</div>}
    </div>
  );
};

NotificationHeaderItem.propTypes = { 
  notification: PropTypes.object,
  size: PropTypes.string,
};

export default NotificationHeaderItem;