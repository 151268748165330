import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import ButtonIcon from '../../common/ButtonIcon/ButtonIcon';
import Button from '../../common/Buttons/Button';
import './homework-card.scss';

const HomeworkCard = ({id, count, title, color, description, testsCount, attempts, isOpen, toggleAccordion, isContinue, load, homeworkElId, token, continueBtn, homeworkBlockId}) => {
  const navigate = useNavigate();
  const bodyRef = useRef(null);
  const [height, setHeight] = useState(0);

  const setAttemptsPage = (id) => {
    localStorage.setItem('homeworkId', JSON.stringify(id));
    navigate('/homework/reviews');
  };

  const setTestPage = (homeworkId, currentBLockId) => {
    localStorage.setItem('homeworkElId', JSON.stringify(homeworkId));
    localStorage.setItem('homeworkId', JSON.stringify(currentBLockId));
    localStorage.setItem('startTest', JSON.stringify(true));
    navigate('/homework/test');
  };

  const setNextTestPage = (homeworkBlockId) => {
    localStorage.setItem('currentTestId', JSON.stringify(homeworkBlockId));
    navigate('/homework/test');
  };

  useEffect(() => {
    if (!load && bodyRef.current) {
      setHeight(isOpen ? bodyRef.current.scrollHeight : 0);
    }
  }, [load, isOpen]);
  
  return (
    <div className={`homework-card ${isOpen ? 'open' : ''} ${color}`}>
      <div className="homework-card__head" onClick={() => toggleAccordion(id)}>
        <div className="homework-card__number">{count}</div>
        <div className="homework-card__title">{title}</div>
        {continueBtn && <Button buttonBg='light' onClick={() => setNextTestPage(homeworkBlockId)}>Продовжити</Button> }
        <Button buttonBg='green' btnSize='md' textSize='md' btnRadius='md' onClick={() => setTestPage(homeworkElId, id, token)}>Пройти</Button>
        <ButtonIcon icon='chevrone-down' iconColor='green' classes='homework-card__arrow' />
      </div>
      <div 
        className="homework-card__body" 
        ref={bodyRef}         
        style={{maxHeight: `${height}px`}}
      >
        <div className="homework-card__content">
          <div className="homework-card-block">
            <div className="homework-card-block__title">Опис:</div>
            <div className="homework-card-block__desc">{description}</div>
          </div>
          <div className="homework-card-block">
            <div className="homework-card-block__title">Кількість тестів:</div>
            <div className="homework-card-block__desc">{testsCount}</div>
          </div>
          <div className="homework-card-block">
            <div className="homework-card-block__title">Кількість спроб:</div>
            <div className="homework-card-block__desc">
              <span className='homework-card-block__desc-num'>{attempts}</span>
              {isContinue && <Button buttonBg='light' onClick={() => setAttemptsPage(id)}>Переглянути</Button>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

HomeworkCard.propTypes = {
  id: PropTypes.number,
  title: PropTypes.string,
  color: PropTypes.string,
  description: PropTypes.string,
  testsCount: PropTypes.number,
  attempts: PropTypes.number,
  isOpen: PropTypes.bool,
  toggleAccordion: PropTypes.func,
  isContinue: PropTypes.bool,
  load: PropTypes.bool,
  homeworkElId: PropTypes.number,
  token: PropTypes.string,
  continueBtn: PropTypes.bool,
  homeworkBlockId: PropTypes.any,
  count: PropTypes.number
};

export default HomeworkCard;