import types from '../types';

const getHomeworkTitle = (payload) => ({
  type: types.homework.GET_HOMEWORK_TITLE,
  payload,
});

const getHomeworkBlocks = (payload) => ({
  type: types.homework.GET_HOMEWORK_BLOCKS,
  payload,
});

const getHomeworkError = (payload) => ({
  type: types.homework.GET_HOMEWORK_ERROR,
  payload,
});

const startFetching = () => ({
  type: types.homework.START_FETCHING_HOMEWORK
});

const stopFetching = () => ({
  type: types.homework.STOP_FETCHING_HOMEWORK,
});

export default {
  getHomeworkTitle,
  getHomeworkError,
  getHomeworkBlocks,
  startFetching,
  stopFetching,
};